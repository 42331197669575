<template>
    <div id="oil">
                
               <el-form :inline="true" :model="searchCustForm" class="demo-form-inline">
                 <el-button type="primary" @click="exportexcel">导出</el-button>
                 &nbsp;&nbsp;&nbsp;
				  <el-button type="primary" @click="preSaveCustomer">添加</el-button>
                  &nbsp;&nbsp;&nbsp;
				 
                  <el-form-item label="加油卡号">
                    <el-input v-model="searchCustForm.oidcard" required="true" placeholder="加油卡号">
                    </el-input>
                  </el-form-item>
				<el-form-item label="类型:">
					  <input type="radio" name="radios11" value="1" v-model="searchCustForm.type"><label>支出</label>
					  <input type="radio" name="radios11" value="0" v-model="searchCustForm.type"><label>收入</label>		           
					  <input type="radio" name="radios11" value="" v-model="searchCustForm.type"><label>全选</label>	
		          </el-form-item>
		
		         <!-- <el-form-item label="金额:">
		            <el-input
		              placeholder="请输入金额"
		              maxlength="50"
		              v-model="searchCustForm.price"
		            />
		          </el-form-item> -->
				  
				  <el-form-item label="备注:">
				    <el-input
				      placeholder="请输入备注"
				      maxlength="50"
				      v-model="searchCustForm.name"
				    />
				  </el-form-item>
				  
				<el-form-item label="时间:">
					<el-date-picker
					 placeholder="请选择时间"   
						type="date"
					 v-model="searchCustForm.time">
					</el-date-picker>
				 
				</el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="searchCust">搜索</el-button>
                  </el-form-item>
                </el-form>
                <el-table
                    :data="carLists"
                    stripe
                    style="width: 100%">
					<el-table-column
					label="编号"
						 type="index"
						 width="150">
					   </el-table-column>
					<!-- <el-table-column
					prop="oilid"
					label="编号"
					width="150">
					</el-table-column> -->

                   <el-table-column
                    prop="oidcard"
                    label="卡号"
                    width="150">
                    </el-table-column>


                    <el-table-column
                    prop="type"
                    label="类型"
                    width="150">
					<template slot-scope="scope">{{ scope.row.type == 1 ? '支出' : '收入' }}</template>
                    </el-table-column>
					
					<el-table-column
					prop="price"
					label="金额">
					</el-table-column>

					<el-table-column
					prop="name"
					label="备注">
					</el-table-column>

					<el-table-column
					prop="time"
					:formatter="dateFormat"
					label="时间">
					</el-table-column>
			

                    <el-table-column
                    label="操作" width="200px">
                    <template slot-scope="scope">
                      
                        <el-button type="primary" 
                        icon="el-icon-delete" 
                        @click="deleteCust(scope.row.oilid)">
                        </el-button>
                      
                        <el-button type="danger" 
                        icon="el-icon-edit" 
                        @click="preUpdateCust(scope.row.oilid)">
                        </el-button>
                    </template>
                </el-table-column>
                </el-table>
		<el-dialog
		        title="添加记录"
		        :visible.sync="addBox"
		        width="50%"
		        :before-close="handleClose"
		       >
		        <el-form
		          ref="addCustDataForm"
		          label-width="100px"
		          v-model="addCustDataForm"
		        >
		          <el-form-item label="卡号:">
					  <el-select v-model="value3" placeholder="请选择" @change="getOidd">
					    <el-option
					        v-for="item in oids"
					        :key="item.oidcard"
					        :label="item.oidcard"
					        :value="item.oidcard">
					    </el-option>
					    </el-select>		         
		          </el-form-item>
				
			
		          <el-form-item label="类型:">
					  <input type="radio" name="radios1" value="1" v-model="addCustDataForm.type"><label>支出</label>
					  <input type="radio" name="radios1" value="0" v-model="addCustDataForm.type"><label>收入</label>		           
		          </el-form-item>
		
		          <el-form-item label="金额:">
		            <el-input
		              placeholder="请输入金额"
		              maxlength="50"
		              v-model="addCustDataForm.price"
		            />
		          </el-form-item>
				  
				  <el-form-item label="备注:">
				    <el-input
				      placeholder="请输入备注"
				      maxlength="50"
				      v-model="addCustDataForm.name"
				    />
				  </el-form-item>
				  
				<el-form-item label="时间:">
					<el-date-picker
					 placeholder="请选择时间"   
						type="date"
					 v-model="addCustDataForm.time">
					</el-date-picker>
				 
				</el-form-item>
		
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="saveCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		<!-- 
		  修改客户对话框 
		  updateBox表示修改对话框显示和隐藏的标志：true显示，false隐藏
		  updateHandleClose表示关闭窗体之前发生的动作
		-->
		      <el-dialog
		        title="修改记录"
		        :visible.sync="updateBox"
		        width="50%"
		        :before-close="updateHandleClose"
		       >
		        <el-form
		          ref="updateCustDataForm"
		          label-width="100px"
		          v-model="updateCarDataForm"
		        >
		          <el-form-item label="卡号:">
		            <el-input
					disabled="true"
		              placeholder="请输入卡号"
		              maxlength="50"
		              v-model="updateCarDataForm.oidcard"
		            />
		          </el-form-item>
		
		         
		          </el-form-item>
		
		          <el-form-item label="类型:">
					  <input type="radio" name="radios2" value="1" v-model="updateCarDataForm.type"><label>支出</label>
					  <input type="radio" name="radios2" value="0" v-model="updateCarDataForm.type"><label>收入</label>
		          
		          </el-form-item>
				  
				  <el-form-item label="金额:">
				    <el-input
				      placeholder="请选择金额"
				      maxlength="50"
				      v-model="updateCarDataForm.price"
				    />
				  </el-form-item>
				  
				  <el-form-item label="备注:">
				    <el-input
				      placeholder="请输入备注"
				      maxlength="50"
				      v-model="updateCarDataForm.name"
				    />
				  </el-form-item>
				  
				  <el-form-item label="时间:">
					  <el-date-picker
					   placeholder="请选择时间"   
					  	type="date"
					   v-model="updateCarDataForm.time">
					  </el-date-picker>
				   
				  </el-form-item>
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="updateCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
			  
			  <el-dialog
			          title="添加加油卡"
			          :visible.sync="addBox2"
			          width="50%"
			         :before-close="handleClose2"
			         >
			          <el-form
			            ref="addCustDataForm2"
			            label-width="100px"
			            v-model="addCustDataForm2"
			          >		
			  	
			  		<el-form-item label="加油卡:">
			  		  <el-input
			  		    placeholder="请输入加油卡"
			  		    maxlength="50"
			  		    v-model="addCustDataForm2.oidcard"
			  		  />
			  		</el-form-item>										
					
			  		</el-form>
			  		<span slot="footer" class="dialog-footer">
			  		    <el-button type="primary" @click="saveCustomer2">确 定</el-button>
			  		  </span>
			  		</el-dialog>
			  
			  
		      <el-pagination v-if="allCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="customerList"
						    @size-change="customerList" >
						</el-pagination>
						<el-pagination v-if="selectCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="searchCust"
						    @size-change="searchCust" >
						</el-pagination>
		    </div>
                
    </div>
</template>

<script>
import moment from "moment"
  export default {
	  
	 
    data() {
      return {
        // 存储后端返回给前端的客户列表
        carLists: [],
		banks: [],
		value1:'',
		value2:'',
		value3:'',
		value4:'',
		addBox2:false,
        // “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
        addBox:false,
        allCustomer:true,
        // “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
        updateBox:false,
        selectCustomer:false,
        // “添加客户”对话框的表单
        addCustDataForm:{
           oilid:'',
           oidcard:'',
           bankid:'',
           allmoney:'',
           type:'',
           price:'',
		   name:'',
		   time:''
        },
		addCustDataForm2:{
		   oilid:'',
		   oidcard:'',
		   bankid:'',
		   allmoney:'',
		   type:'',
		   price:'',
		   name:'',
		   time:''
		},
        // “修改客户”对话框的表单
        updateCarDataForm:{
           oilid:'',
           oidcard:'',
           bankid:'',
           allmoney:'',
           type:'',
           price:'',
           name:'',
           time:''
        },
		oids: [],
		oidcard:'',
        // 搜索表单
        searchCustForm:{
           oidcard:'',
           bankid:'',
           allmoney:'',
           type:'',
           price:'',
           name:'',
           time:''
        },
		bankid:'',
        total : 0,
				pageNum : 1,
				pageSize : 10,
      }
    },
    methods: {
		getOid(value){
		  this.bankid = value;
		},
		getOidd(value){
		  this.oidcard = value;
		},
         dateFormat(row,column){
           let date = row[column.property];
           if(date === undefined){
             return ''
           } ;
           return moment(date).format("yyyy-MM-DD")
         },
		 //导出
		 exportexcel(){
		 			 this.$alert(
		 			         <div class="el-message-box--custom-body">
		 			           <div style="font-size:16px">确认导出所有数据？</div>
		 			         </div>,
		 			         "数据导出",
		 			         {
		 			           showCancelButton: true,				          
		 			           customClass: "el-message-box--noicon el-message-box--custom",
		 			           confirmButtonText: "导出"
		 			         }
		 			       ).then(() => {
		 			         window.open('https://jingjie-back.goomo.top/oil/export', "_blank");
		 					 // window.open('/car/export', "_blank");
		 			       });			 	 
		   
		 },
		 // 收搜
		 searchCust(){
		 	console.log("11"); 	
		 	this.$axios.post('/oil/search/'+this.pageNum+'/'+this.pageSize,		 	
		 			{				 				
		 				oidcard: this.searchCustForm.oidcard,		 						 				
		 				type: this.searchCustForm.type,
		 				// price: this.searchCustForm.price,
		 				name: this.searchCustForm.name,
		 				time: this.searchCustForm.time
		 			})
		 	.then((resp) =>{
		 	if(resp.data.data.empList != null){
		 			this.carLists = resp.data.data.empList.list;
		 			this.total = resp.data.data.empList.total;
		 			this.pageNum = resp.data.data.empList.pageNum;
		 			this.allCustomer = false;
		 			this.selectCustomer = true;
		 		}else
		 		{
		 			this.$message.error("没有相关用户信息");
		 		}
		 	}).catch((error) =>{
		 		console.log(error);
		 		this.$message.error("请求发送失败");
		 	})	 		
		 	},
		// 修改之前的数据回显
		preUpdateCust(oilid) {
		   this.$axios.get('/oil/get/'+oilid)
		   .then((resp)=>{
		      // 条件成立：表示能够从后端获取对应的客户信息，完成数据回显
		      if (resp.data.message == 'success') {
		        // 显示“修改客户”对话框
		        this.updateBox = true;
		        // 将后端的客户信息赋值给前端定义的修改表单
		        this.updateCarDataForm = resp.data.data.oilById;
				this.value2 =  this.updateCarDataForm.bankcard;
		      } else {
		        this.$message.error("没有找到对应的客户信息");
		      }
		   })
		   .catch((error)=>{
		      this.$message.error("修改客户信息数据回显请求发送失败");
		      console.log(error);
		   })
		   this.$axios.get('/bank/getbd').then((resp)=>{
		     this.banks = resp.data.data.bankcardss;
		   			 console.log(this.banks);
		   }).catch((error)=>{
		     this.$message.error("请求失败");
		     console.log(error);
		   })
		   
		   
		},
		// 修改客户信息
		updateCustomer(){
		    this.$axios.post('/oil/update',{
		      oilid: this.updateCarDataForm.oilid,
		      oidcard: this.updateCarDataForm.oidcard,
			  bankid:this.bankid,
		      allmoney: this.updateCarDataForm.allmoney,
		      type: this.updateCarDataForm.type,
		      price: this.updateCarDataForm.price,
			  name: this.updateCarDataForm.name,
			  time: this.updateCarDataForm.time
			 
		    }).then((resp)=>{
		      // 条件成立：修改成功，否则修改失败
		      if (resp.data.code == 20000) {
		         this.$message.success("修改成功");
		      } else {
		         this.$message.error(resp.data.message);
		      }
		      // 隐藏对话框
		      this.updateBox = false;
		      // 重新查询修改之后的客户信息
		      this.customerList();
		    }).catch((error)=>{
		       this.$message.error("修改客户信息请求发送失败");
		       console.log(error);
		    })
		    
		},
		// 根据客户ID删除客户信息
		deleteCust(oilid){
		    this.$confirm("确定要删除吗","提示",{
		        confirmButtonText:'确定',
		        cannelButtonText:'取消',
		        type:'警告'
		    })
		    // 点击确定执行删除操作
		    .then(()=>{
		          // 删除操作之前先做check(验证当前客户有没有管理送水历史，如果有提示用户该客户有
		          // 对应的送水历史关联)
		          this.$axios.post('/oil/delete/'+oilid)
		          // then方法的resp参数封装了后端返回给前端的数据
		                    .then((resp)=>{
		                        // 条件成立：表示该客户有对应的送水历史关联，阻止删除
		                       if(resp.data.code == 20000) {
		                               this.$message.success("删除成功");
		                           } else {
		                               this.$message.error(resp.data.message);
		                           }
		                           this.customerList();
		                    }).catch((error)=>{
                                    this.$message.error("删除客户信息请求发送失败");
                                    console.log(error);
                                })
							
		          })
		},
		// 预添加客户信息，弹出“添加客户”对话框
		preSaveCustomer(){
			this.$axios.get('/bank/getbd').then((resp)=>{
			  this.banks = resp.data.data.bankcardss;
						 console.log(this.banks);
			}).catch((error)=>{
			  this.$message.error("请求失败");
			  console.log(error);
			})
			this.$axios.get('/oillist/getoid').then((resp)=>{
			  this.oids = resp.data.data.oidcard;
						 console.log(this.banks);
			}).catch((error)=>{
			  this.$message.error("请求失败");
			  console.log(error);
			})
		    this.addBox = true;
		
		},
		
		preSave1Customer(){
		
		    this.addBox2 = true;
		
		},
		
		// 关闭对话框之前做的动作
		handleClose(done){
		    // 清空上一次表单数据
		    this.addCustDataForm.oidcard='';
		    this.addCustDataForm.bankid='';
		    this.addCustDataForm.allmoney='';
			this.addCustDataForm.type='';
			this.addCustDataForm.price='';
			this.addCustDataForm.name='';
			this.addCustDataForm.time='';
			this.bankid='';
			this.oidcard='';
			this.value1='';
			this.value2='';
			this.value3='';
		    // 关闭对话框
		    done();
		},
		handleClose2(done){
		    // 清空上一次表单数据
		    this.addCustDataForm2.oidcard='';
		    this.addCustDataForm2.bankid='';
		    this.addCustDataForm2.allmoney='';
			this.addCustDataForm2.type='';
			this.addCustDataForm2.price='';
			this.addCustDataForm2.name='';
			this.addCustDataForm2.time='';
			this.bankid='';
		    // 关闭对话框
		    done();
		},
		
		// 提交对话框添加客户信息
		saveCustomer(){
		    this.$axios.post('/oil/insert2',{
		        // 前端使用JSON格式将表单参数传入给后端
		       oidcard: this.oidcard,
			   bankid :this.bankid,
		       allmoney: this.addCustDataForm.allmoney,
		       type: this.addCustDataForm.type,
		       price: this.addCustDataForm.price,
		       name: this.addCustDataForm.name,
		       time: this.addCustDataForm.time
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error(resp.data.message);
		        }
		        // 重新查询客户信息
		        this.customerList();
		        // 关闭对话框
		        this.addBox = false;
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
		// 提交对话框添加客户信息
		saveCustomer2(){
		    this.$axios.post('/oil/insert',{
		        // 前端使用JSON格式将表单参数传入给后端
		       oidcard: this.addCustDataForm2.oidcard,
			   
		       allmoney: this.addCustDataForm2.allmoney,
		       type: 0,
		       price: 0,
		       name: "加入加油卡"
		       
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error(resp.data.message);
		        }
		        // 重新查询客户信息
		        this.customerList();
		        // 关闭对话框
		        this.addBox2 = false;
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
        // 向后端发起异步请求，查询所有的客户信息
      customerList(){
					this.$axios.get('/oil/all/'+this.pageNum+"/"+this.pageSize)
					        .then((resp)=>{
					            // 获取后端返回给前端的信息
					            if (resp.data.message == 'success') {
									 // this.$message.error("向后端发起客户列表请求成功");
					                // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
					                this.carLists = resp.data.data.oilslist.list;
									this.total = resp.data.data.oilslist.total;
									this.pageNum = resp.data.data.oilslist.pageNum;
									this.allCustomer = true;
									this.selectCustomer = false;
					            }
					        }).catch((error)=>{
					            this.$message.error("向后端发起客户列表请求失败");
					            console.log(error);
					        })
					    }
					},
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.customerList()
    },
  }
</script>
