<template>
    <div id="car">
                
               <el-form :inline="true" :model="searchCustForm" class="demo-form-inline">
				   <el-button type="primary" @click="exportexcel">导出</el-button>
				   &nbsp;&nbsp;&nbsp;
                  <el-button type="primary" @click="preSaveCustomer">添加</el-button>
                  &nbsp;&nbsp;&nbsp;
                  <el-form-item label="车牌号">
                    <el-input v-model="searchCustForm.carone" required="true" placeholder="车牌号">
                    </el-input>
                  </el-form-item>
				<el-form-item label="挂车车牌号:">
		            <el-input
		              placeholder="请输入挂车车牌号"
		              maxlength="50"
		              v-model="searchCustForm.cartwo"
		            />
		          </el-form-item>
		          <el-form-item label="道路运输证:">
		            <el-input
		              placeholder="请输入道路运输证"
		              maxlength="50"
		              v-model="searchCustForm.dlyszcardone"
					  
		            />
		          </el-form-item>
					<el-form-item >
				    <el-date-picker
				     placeholder="保险开始时间"   
				    	type="date"
				     v-model="searchCustForm.stime">
				    </el-date-picker>				 
				    <el-date-picker
				     placeholder="保险结束时间"   
				    	type="date"
				     v-model="searchCustForm.etime">
				    </el-date-picker>
				  </el-form-item>
				  
                  <el-form-item>
                    <el-button type="primary" @click="searchCust">搜索</el-button>
                  </el-form-item>
                </el-form>
                <el-table
                    :data="carLists"
                    stripe
                    style="width: 100%">
					
					 <el-table-column
					 label="编号"
					 	 type="index"
					 	 width="150">
					    </el-table-column>
					
				<!-- 	<el-table-column
					prop="cid"
					label="编号"
					width="150">
					</el-table-column> -->

                   <el-table-column
                    prop="carone"
                    label="车牌号"
                    width="200">
                    </el-table-column>

                    <el-table-column
                    prop="cartwo"
                    label="挂车车牌号"
                    width="200">
                    </el-table-column>

                    <el-table-column
                    prop="dlyszcardone"				
                    label="道路运输证"
					width="200">								
                    </el-table-column>

                  
					<el-table-column
					prop="stime"
					:formatter="dateFormat"
					label="保险开始时间">
					</el-table-column>

					<el-table-column
					prop="etime"
					:formatter="dateFormat"
					label="保险结束时间">
					</el-table-column>

			

                    <el-table-column
                    label="操作" width="200px">
                    <template slot-scope="scope">
                      
                        <el-button type="primary" 
                        icon="el-icon-delete" 
                        @click="deleteCust(scope.row.cid)">
                        </el-button>
                      
                        <el-button type="danger" 
                        icon="el-icon-edit" 
                        @click="preUpdateCust(scope.row.cid)">
                        </el-button>
                    </template>
                </el-table-column>
                </el-table>
		<el-dialog
		        title="添加客户"
		        :visible.sync="addBox"
		        width="50%"
		        :before-close="handleClose"
		       >
		        <el-form 
				
				  :rules="rules"
		          ref="addCustDataForm"
		          label-width="100px"
		          v-model="addCustDataForm"
		        >
		          <el-form-item label="车牌号:" prop="carone">
		            <el-input					
		              placeholder="请输入车牌号"
		              maxlength="50"
		              v-model="addCustDataForm.carone"
		            />
		          </el-form-item>
		
		          <el-form-item label="挂车车牌号:">
		            <el-input
		              placeholder="请输入挂车车牌号"
		              maxlength="50"
		              v-model="addCustDataForm.cartwo"
		            />
		          </el-form-item>
		          <el-form-item label="道路运输证:">
		            <el-input
		              placeholder="请输入道路运输证"
		              maxlength="50"
		              v-model="addCustDataForm.dlyszcardone"
		            />
		          </el-form-item>
		
		          <el-form-item label="保险开始时间:">
		            <el-date-picker
		             placeholder="请选择时间"   
		            	type="date"
		             v-model="addCustDataForm.stime">
		            </el-date-picker>
		          </el-form-item>
		          
		          
		          <el-form-item label="保险结束时间:">
		            <el-date-picker
		             placeholder="请选择时间"   
		            	type="date"
		             v-model="addCustDataForm.etime">
		            </el-date-picker>
		          </el-form-item>
		          
				  
				 
				
		
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="saveCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		<!-- 
		  修改客户对话框 
		  updateBox表示修改对话框显示和隐藏的标志：true显示，false隐藏
		  updateHandleClose表示关闭窗体之前发生的动作
		-->
		      <el-dialog
		        title="修改车辆"
		        :visible.sync="updateBox"
		        width="50%"
		        :before-close="updateHandleClose"
		       >
		        <el-form
		          ref="updateCustDataForm"
		          label-width="100px"
		          v-model="updateCarDataForm"
		        >
		          <el-form-item label="车牌号:">
		            <el-input
		              placeholder="请输入车牌号"
		              maxlength="50"
		              v-model="updateCarDataForm.carone"
		            />
		          </el-form-item>
		
		          <el-form-item label="挂车车牌号:">
		            <el-input
		              placeholder="请输入挂车车牌号"
		              maxlength="50"
		              v-model="updateCarDataForm.cartwo"
		            />
		          </el-form-item>
		          <el-form-item label="道路运输证:">
		            <el-input
		              placeholder="请输入道路运输证"
		              maxlength="50"
		              v-model="updateCarDataForm.dlyszcardone"
					  
		            />
		          </el-form-item>
				          
				  
				  <el-form-item label="保险开始时间:">
				    <el-date-picker
				     placeholder="请选择时间"   
				    	type="date"
				     v-model="updateCarDataForm.stime">
				    </el-date-picker>
				  </el-form-item>
				  
				  
				  <el-form-item label="保险结束时间:">
				    <el-date-picker
				     placeholder="请选择时间"   
				    	type="date"
				     v-model="updateCarDataForm.etime">
				    </el-date-picker>
				  </el-form-item>
				  
				  
				  
				 
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="updateCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		      <el-pagination v-if="allCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="customerList"
						    @size-change="customerList" >
						</el-pagination>
						<el-pagination v-if="selectCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="searchCust"
						    @size-change="searchCust" >
						</el-pagination>
		    </div>
                
    </div>
</template>

<script>
import moment from "moment"
  export default {
	  
	 
    data() {
      return {
		  rules: {
		    carone: [
		      { required: true, message: '请输入车牌号', trigger: 'blur' }
		    
		    ],
		    
			},
        // 存储后端返回给前端的客户列表
        carLists: [],
        // “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
        addBox:false,
		
        allCustomer:true,
        // “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
        updateBox:false,
        selectCustomer:false,
        // “添加客户”对话框的表单
        addCustDataForm:{
           cid:'',
           carone:'',
           cartwo:'',
           dlyszcardone:'',
           dlyszcardtwo:'',
           ky:'',
		   stime:'',
		   etime:''
        },
        // “修改客户”对话框的表单
        updateCarDataForm:{
            cid:'',
            carone:'',
            cartwo:'',
            dlyszcardone:'',
            dlyszcardtwo:'',
			ky:'',
			stime:'',
			etime:''
        },
        // 搜索表单
        searchCustForm:{
           carone:'',
           cartwo:'',
           dlyszcardone:'',
           dlyszcardtwo:'',
           ky:'',
           stime:'',
           etime:''
        },
        total : 0,
				pageNum : 1,
				pageSize : 10,
      }
    },
    methods: {
         dateFormat(row,column){
           let date = row[column.property];
           if(date === undefined){
             return ''
           } ;
           return moment(date).format("yyyy-MM-DD")
         },
		//导出
		exportexcel(){
					 this.$alert(
					         <div class="el-message-box--custom-body">
					           <div style="font-size:16px">确认导出所有数据？</div>
					         </div>,
					         "数据导出",
					         {
					           showCancelButton: true,				          
					           customClass: "el-message-box--noicon el-message-box--custom",
					           confirmButtonText: "导出"
					         }
					       ).then(() => {
					         window.open('https://jingjie-back.goomo.top/car/export', "_blank");
							 // window.open('/car/export', "_blank");
					       });			 	 
		  
		},
		 // export function getExportExcelUrl() {
		 //   return request.defaults.baseURL + '/xxx/exportExcel';
		 // },
		 // 收搜
		 searchCust(){
		 	console.log("11"); 	
		 	this.$axios.post('/car/search/'+this.pageNum+'/'+this.pageSize,		 	
		 			{				
		 				carone: this.searchCustForm.carone,
		 				cartwo: this.searchCustForm.cartwo,
		 				dlyszcardone: this.searchCustForm.dlyszcardone,
		 				dlyszcardtwo: this.searchCustForm.dlyszcardtwo,
		 				ky: this.searchCustForm.ky,
		 				stime: this.searchCustForm.stime,
		 				etime: this.searchCustForm.etime
		 			})
		 	.then((resp) =>{
		 	if(resp.data.data.empList != null){
		 			this.carLists = resp.data.data.empList.list;
		 			this.total = resp.data.data.empList.total;
		 			this.pageNum = resp.data.data.empList.pageNum;
		 			this.allCustomer = false;
		 			this.selectCustomer = true;
		 		}else
		 		{
		 			this.$message.error("没有相关用户信息");
		 		}
		 	}).catch((error) =>{
		 		console.log(error);
		 		this.$message.error("请求发送失败");
		 	})	 		
		 	},
			
		// 修改之前的数据回显
		preUpdateCust(cid) {
		   this.$axios.get('/car/getcar/'+cid)
		   .then((resp)=>{
		      // 条件成立：表示能够从后端获取对应的客户信息，完成数据回显
		      if (resp.data.message == 'success') {
		        // 显示“修改客户”对话框
		        this.updateBox = true;
		        // 将后端的客户信息赋值给前端定义的修改表单
		        this.updateCarDataForm = resp.data.data.carById;
		      } else {
		        this.$message.error("没有找到对应的客户信息");
		      }
		   })
		   .catch((error)=>{
		      this.$message.error("修改客户信息数据回显请求发送失败");
		      console.log(error);
		   })
		},
		// 修改客户信息
		updateCustomer(){
		    this.$axios.post('/car/update',{
		      cid: this.updateCarDataForm.cid,
		      carone: this.updateCarDataForm.carone,
		      cartwo: this.updateCarDataForm.cartwo,
		      dlyszcardone: this.updateCarDataForm.dlyszcardone,
		      dlyszcardtwo: this.updateCarDataForm.dlyszcardtwo,
			  ky: this.updateCarDataForm.ky,
			  stime: this.updateCarDataForm.stime,
			  etime: this.updateCarDataForm.etime
			 
		    }).then((resp)=>{
		      // 条件成立：修改成功，否则修改失败
		      if (resp.data.code == 20000) {
		         this.$message.success("修改成功");
		      } else {
		         this.$message.error(resp.data.message);
		      }
		      // 隐藏对话框
		      this.updateBox = false;
		      // 重新查询修改之后的客户信息
		      this.customerList();
		    }).catch((error)=>{
		       this.$message.error("修改客户信息请求发送失败");
		       console.log(error);
		    })
		    
		},
		// 根据客户ID删除客户信息
		deleteCust(cid){
		    this.$confirm("确定要删除吗","提示",{
		        confirmButtonText:'确定',
		        cannelButtonText:'取消',
		        type:'警告'
		    })
		    // 点击确定执行删除操作
		    .then(()=>{
		          // 删除操作之前先做check(验证当前客户有没有管理送水历史，如果有提示用户该客户有
		          // 对应的送水历史关联)
		          this.$axios.post('/car/delete/'+cid)
		          // then方法的resp参数封装了后端返回给前端的数据
		                    .then((resp)=>{
		                        // 条件成立：表示该客户有对应的送水历史关联，阻止删除
		                       if(resp.data.code == 20000) {
		                               this.$message.success("删除成功");
		                           } else {
		                               this.$message.error(resp.data.message);
		                           }
		                           this.customerList();
		                    }).catch((error)=>{
                                    this.$message.error("删除客户信息请求发送失败");
                                    console.log(error);
                                })
							
		          })
		},
		// 预添加客户信息，弹出“添加客户”对话框
		preSaveCustomer(){
		    this.addBox = true;
		
		},
		// 关闭对话框之前做的动作
		handleClose(done){
		    // 清空上一次表单数据
		    this.addCustDataForm.carone='';
		    this.addCustDataForm.cartwo='';
		    this.addCustDataForm.dlyszcardone='';
			this.addCustDataForm.dlyszcardtwo='';
			this.addCustDataForm.ky='';
			this.addCustDataForm.stime='';
			this.addCustDataForm.etime='';
		    // 关闭对话框
		    done();
		},
		// 提交对话框添加客户信息
		saveCustomer(){
		    this.$axios.post('/car/insert',{
		        // 前端使用JSON格式将表单参数传入给后端
		       
		       carone: this.addCustDataForm.carone,
		       cartwo: this.addCustDataForm.cartwo,
		       dlyszcardone: this.addCustDataForm.dlyszcardone,
		       dlyszcardtwo: this.addCustDataForm.dlyszcardtwo,
		       ky: this.addCustDataForm.ky,
			   stime: this.addCustDataForm.stime,
			   etime: this.addCustDataForm.etime
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error(resp.data.message);
		        }
		        // 重新查询客户信息
		        this.customerList();
		        // 关闭对话框
		        this.addBox = false;
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
        // 向后端发起异步请求，查询所有的客户信息
      customerList(){
					this.$axios.get('/car/allcar/'+this.pageNum+"/"+this.pageSize)
					        .then((resp)=>{
					            // 获取后端返回给前端的信息
					            if (resp.data.message == 'success') {
									 // this.$message.error("向后端发起客户列表请求成功");
					                // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
					                this.carLists = resp.data.data.carLists.list;
									this.total = resp.data.data.carLists.total;
									this.pageNum = resp.data.data.carLists.pageNum;
									this.allCustomer = true;
									this.selectCustomer = false;
					            }
					        }).catch((error)=>{
					            this.$message.error("向后端发起客户列表请求失败");
					            console.log(error);
					        })
					    }
					},
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.customerList()
    },
  }
</script>
