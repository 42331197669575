<template>
    <div id="oil">
                
               <el-form :inline="true" :model="searchCustForm" class="demo-form-inline">
                  <el-button type="primary" @click="preSaveCustomer">添加加油卡</el-button>
                  &nbsp;&nbsp;&nbsp;
				  
                 <!-- <el-form-item label="加油卡">
                    <el-input v-model="searchCustForm.name" required="true" placeholder="加油卡">
                    </el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button type="primary" @click="searchCust">搜索</el-button>
                  </el-form-item> -->
                </el-form>
                <el-table
                    :data="carLists"
                    stripe
                    style="width: 100%">
					<el-table-column
					label="编号"
						 type="index"
						 width="150">
					   </el-table-column>
					<!-- <el-table-column
					prop="oid"
					label="编号"
					width="280">
					</el-table-column> -->

                   <el-table-column
                    prop="oidcard"
                    label="卡号"
                    width="280">
                    </el-table-column>

                    <el-table-column
                    prop="bankcard"
                    label="关联银行卡"
                    width="280">
                    </el-table-column>            
					
					<el-table-column
					prop="allmoney"
					:formatter="rounding"
					label="余额"
					width="280">
					</el-table-column>
											

                    <el-table-column
                    label="操作" width="200px">
                    <template slot-scope="scope">
                      
                        <el-button type="primary" 
                        icon="el-icon-delete" 
                        @click="deleteCust(scope.row.oid)">
                        </el-button>
                      
                        <el-button type="danger" 
                        icon="el-icon-edit" 
                        @click="preUpdateCust(scope.row.oid)">
                        </el-button>
                    </template>
                </el-table-column>
                </el-table>
		<el-dialog
		        title="添加记录"
		        :visible.sync="addBox"
		        width="50%"
		        :before-close="handleClose"
		       >
		        <el-form
		          ref="addCustDataForm"
		          label-width="100px"
		          v-model="addCustDataForm"
		        >
		          <el-form-item label="卡号:">					 
		            <el-input
		              placeholder="请输入卡号"
		              maxlength="50"
		              v-model="addCustDataForm.oidcard"
		            />
		          </el-form-item>
													    		          
		
		          <el-form-item label="余额:">
		            <el-input
					
		              placeholder="请输入余额"
					  
		              maxlength="50"
		              v-model="addCustDataForm.allmoney"
		            />
		          </el-form-item>				  				 
				  
				<el-form-item label="关联银行卡:">
					<el-select v-model="value1" placeholder="请选择" @change="getOid">
					  <el-option
					      v-for="item in banks"
					      :key="item.bankcard"
					      :label="item.bankcard"
					      :value="item.bankcard">
					  </el-option>
					  </el-select>				  
				</el-form-item>
		
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="saveCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		<!-- 
		  修改客户对话框 
		  updateBox表示修改对话框显示和隐藏的标志：true显示，false隐藏
		  updateHandleClose表示关闭窗体之前发生的动作
		-->
		      <el-dialog
		        title="修改记录"
		        :visible.sync="updateBox"
		        width="50%"
		        :before-close="updateHandleClose"
		       >
		        <el-form
		          ref="updateCustDataForm"
		          label-width="100px"
		          v-model="updateCarDataForm"
		        >
		          <el-form-item label="卡号:">
		              <el-input
		                placeholder="请输入卡号"
		                maxlength="50"
		                v-model="updateCarDataForm.oidcard"
		              />
		            </el-form-item>
		          									    		          
		          		
		            <el-form-item label="余额:">
		              <el-input
		                placeholder="请输入余额"
		                maxlength="50"
		                v-model="updateCarDataForm.allmoney"
		              />
		            </el-form-item>		            
		           
		            
		          <el-form-item label="关联银行卡:">
		          	<el-select v-model="value2" placeholder="请选择" @change="getOid">
		          	  <el-option
		          	      v-for="item in banks"
		          	      :key="item.bankcard"
		          	      :label="item.bankcard"
		          	      :value="item.bankcard">
		          	  </el-option>
		          	  </el-select>				  
		          </el-form-item>
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="updateCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
			  			 
			  
			  
		      <el-pagination v-if="allCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="customerList"
						    @size-change="customerList" >
						</el-pagination>
						<el-pagination v-if="selectCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="searchCust"
						    @size-change="searchCust" >
						</el-pagination>
		    </div>
</template>

<script>
import moment from "moment"
  export default {
	  
	 
    data() {
      return {
        // 存储后端返回给前端的客户列表
        carLists: [],
		banks: [],
		value1:'',
		value2:'',
		value3:'',
		value4:'',
		addBox2:false,
        // “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
        addBox:false,
        allCustomer:true,
        // “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
        updateBox:false,
        selectCustomer:false,
        // “添加客户”对话框的表单
        addCustDataForm:{
           oid:'',
           oidcard:'',
           bankcard:'',
           allmoney:''
           
        },
		addCustDataForm2:{
		  oid:'',
		  oidcard:'',
		  bankcard:'',
		  allmoney:''
		},
        // “修改客户”对话框的表单
        updateCarDataForm:{
          oid:'',
          oidcard:'',
          bankcard:'',
          allmoney:''
        },
		bankcard:'',
		oids: [],
		oidcard:'',
        // 搜索表单
        searchCustForm:{
            custName:''
        },
		bankid:'',
        total : 0,
				pageNum : 1,
				pageSize : 3,
      }
    },
    methods: {
		getOid(value){
		  this.bankcard = value;
		},
		getOidd(value){
		  this.oidcard = value;
		},
		rounding(row,column) {
		      return parseFloat(row[column.property]).toFixed(2)
		    },
         dateFormat(row,column){
           let date = row[column.property];
           if(date === undefined){
             return ''
           } ;
           return moment(date).format("yyyy-MM-DD")
         },
		// 修改之前的数据回显
		preUpdateCust(oid) {
		   this.$axios.get('/oillist/get/'+oid)
		   .then((resp)=>{
		      // 条件成立：表示能够从后端获取对应的客户信息，完成数据回显
		      if (resp.data.message == 'success') {
		        // 显示“修改客户”对话框
		        this.updateBox = true;
		        // 将后端的客户信息赋值给前端定义的修改表单
		        this.updateCarDataForm = resp.data.data.oidById;
				this.value2 =  this.updateCarDataForm.bankcard;
		      } else {
		        this.$message.error("没有找到对应的客户信息");
		      }
		   })
		   .catch((error)=>{
		      this.$message.error("修改客户信息数据回显请求发送失败");
		      console.log(error);
		   })
		   this.$axios.get('/bankcard/getbank').then((resp)=>{
		     this.banks = resp.data.data.bankcard;
		   			 console.log(this.banks);
		   }).catch((error)=>{
		     this.$message.error("请求失败");
		     console.log(error);
		   })
		   
		   
		},
		// 修改客户信息
		updateCustomer(){
		    this.$axios.post('/oillist/update',{
		      oid: this.updateCarDataForm.oid,
		      oidcard: this.updateCarDataForm.oidcard,
			  bankcard:this.bankcard,
		      allmoney: this.updateCarDataForm.allmoney		      
		      
			 
			 
		    }).then((resp)=>{
		      // 条件成立：修改成功，否则修改失败
		      if (resp.data.code == 20000) {
		         this.$message.success("修改成功");
		      } else {
		         this.$message.error("修改失败");
		      }
		      // 隐藏对话框
		      this.updateBox = false;
		      // 重新查询修改之后的客户信息
		      this.customerList();
		    }).catch((error)=>{
		       this.$message.error("修改客户信息请求发送失败");
		       console.log(error);
		    })
		    
		},
		// 根据客户ID删除客户信息
		deleteCust(oid){
		    this.$confirm("确定要删除吗","提示",{
		        confirmButtonText:'确定',
		        cannelButtonText:'取消',
		        type:'警告'
		    })
		    // 点击确定执行删除操作
		    .then(()=>{
		          // 删除操作之前先做check(验证当前客户有没有管理送水历史，如果有提示用户该客户有
		          // 对应的送水历史关联)
		          this.$axios.post('/oillist/delete/'+oid)
		          // then方法的resp参数封装了后端返回给前端的数据
		                    .then((resp)=>{
		                        // 条件成立：表示该客户有对应的送水历史关联，阻止删除
		                       if(resp.data.code == 20000) {
		                               this.$message.success("删除成功");
		                           } else {
		                               this.$message.error("删除失败");
		                           }
		                           this.customerList();
		                    }).catch((error)=>{
                                    this.$message.error("删除客户信息请求发送失败");
                                    console.log(error);
                                })
							
		          })
		},
		// 预添加客户信息，弹出“添加客户”对话框
		preSaveCustomer(){
			this.$axios.get('/bankcard/getbank').then((resp)=>{
			  this.banks = resp.data.data.bankcard;
						 console.log(this.banks);
			}).catch((error)=>{
			  this.$message.error("请求失败");
			  console.log(error);
			})
			
		    this.addBox = true;
		
		},
		
		
		
		// 关闭对话框之前做的动作
		handleClose(done){
		    // 清空上一次表单数据
		    this.addCustDataForm.oidcard='';
		    this.addCustDataForm.bankid='';
		    this.addCustDataForm.allmoney='';
			this.addCustDataForm.type='';
			this.addCustDataForm.price='';
			this.addCustDataForm.name='';
			this.addCustDataForm.time='';
			this.bankcard='';
			this.oidcard='';
			this.value1='';
			this.value2='';
			this.value3='';
		    // 关闭对话框
		    done();
		},
		
		
		// 提交对话框添加客户信息
		saveCustomer(){
		    this.$axios.post('/oillist/insert',{
		        // 前端使用JSON格式将表单参数传入给后端
		       oidcard: this.addCustDataForm.oidcard,
			   bankcard :this.bankcard,
		       allmoney: this.addCustDataForm.allmoney
		       
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error("添加失败");
		        }
		        // 重新查询客户信息
		        this.customerList();
		        // 关闭对话框
		        this.addBox = false;
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
		// 提交对话框添加客户信息
		
        // 向后端发起异步请求，查询所有的客户信息
      customerList(){
					this.$axios.get('/oillist/all')
					        .then((resp)=>{
					            // 获取后端返回给前端的信息
					            if (resp.data.message == 'success') {
									 // this.$message.error("向后端发起客户列表请求成功");
					                // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
					                this.carLists = resp.data.data.oidlist;
					            }
					        }).catch((error)=>{
					            this.$message.error("向后端发起客户列表请求失败");
					            console.log(error);
					        })
					    }
					},
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.customerList()
    },
  }
</script>
