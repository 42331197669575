import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Vue使用import xxx from xxx路径的方式导入文件
// Element相当于变量名，为导入的文件起一个名称。方便后续的使用
import Element from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"
import axios from 'axios'

// 配置全局element-ui
Vue.use(Element)
//  配置全局的axios。使用方式为： this.$axios
Vue.prototype.$axios = axios

// axios.defaults.baseURL='http://localhost:8099'
// request.defaults.baseURL='http://localhost:8099'
axios.defaults.baseURL='https://jingjie-back.goomo.top'
Vue.config.productionTip = false
document.title="静捷物流管理系统"
Element.Dialog.props.closeOnClickModal.default = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
