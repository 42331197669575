<template>
    <div id="car">
                
              <el-form :inline="true"  class="demo-form-inline">
				  <el-button type="primary" @click="exportexcel">导出</el-button>
				  &nbsp;&nbsp;&nbsp;
				  <el-form-item label="运单编号">
				    <el-input v-model="searchCustForm.yid" required="true" placeholder="运单编号">
				    </el-input>
				  </el-form-item>
				  <el-form-item label="备注">
				    <el-input v-model="searchCustForm.bz" required="true" placeholder="备注">
				    </el-input>
				  </el-form-item>
                <el-form-item>
                  <el-date-picker
                      v-model="searchCustForm.stime"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="searchCust">查询</el-button>
                </el-form-item>
              </el-form>
                <el-table
                    :data="carLists"
                    stripe
                    style="width: 100%">
					
					<el-table-column
					label="编号"
						 type="index"
						 width="150">
					   </el-table-column>					

                   <el-table-column
                    prop="yid"
                    label="运单编号"
                    width="200">
                    </el-table-column>

                    <el-table-column
                    prop="sprice"
					:formatter="rounding"
                    label="金额"
                    width="200">
                    </el-table-column>

                    <el-table-column
                    prop="stime"	
					:formatter="dateFormat"
                    label="时间"
					width="200">
                    </el-table-column>

                   <el-table-column
                   prop="bz"
                   label="说明"
                   width="200">
                   </el-table-column>
				
                </el-table>
		
		      <el-pagination v-if="allCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="customerList"
						    @size-change="customerList" >
						</el-pagination>
						<el-pagination v-if="selectCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="searchCust"
						    @size-change="searchCust" >
						</el-pagination>
		    </div>
                
  
</template>

<script>
import moment from "moment"
  export default {
	  
	 
    data() {
      return {
        // 存储后端返回给前端的客户列表
        carLists: [],
        // “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
        
        allCustomer:true,
        // “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
        selectCustomer:false,
        // “添加客户”对话框的表单
        // 搜索表单
        searchCustForm:{
            yid:'',
			stime:'',
			sprice:'',
			bz:'',
        },
        total : 0,
				pageNum : 1,
				pageSize : 10,
      }
    },
    methods: {
         dateFormat(row,column){
           let date = row[column.property];
           if(date === undefined){
             return ''
           } ;
           return moment(date).format("yyyy-MM-DD")
         },
		 rounding(row,column) {
		       return parseFloat(row[column.property]).toFixed(2)
		     },
		//导出
		 exportexcel(){
		 			 this.$alert(
		 			         <div class="el-message-box--custom-body">
		 			           <div style="font-size:16px">确认导出所有数据？</div>
		 			         </div>,
		 			         "数据导出",
		 			         {
		 			           showCancelButton: true,				          
		 			           customClass: "el-message-box--noicon el-message-box--custom",
		 			           confirmButtonText: "导出"
		 			         }
		 			       ).then(() => {
		 			         window.open('https://jingjie-back.goomo.top/money/export', "_blank");
		 					 // window.open('/car/export', "_blank");
		 			       });			 	 
		   
		 },
		searchCust(){
		 	console.log("11"); 	
		 	this.$axios.post('/money/search/'+this.pageNum+'/'+this.pageSize,		 	
		 			{				
		 				yid: this.searchCustForm.yid,
		 				stime: this.searchCustForm.stime,
		 				bz: this.searchCustForm.bz
		 					
		 			})
		 	.then((resp) =>{
		 	if(resp.data.data.empList != null){
		 			this.carLists = resp.data.data.empList.list;
		 			this.total = resp.data.data.empList.total;
		 			this.pageNum = resp.data.data.empList.pageNum;
		 			this.allCustomer = false;
		 			this.selectCustomer = true;
		 		}else
		 		{
		 			this.$message.error("没有相关用户信息");
		 		}
		 	}).catch((error) =>{
		 		console.log(error);
		 		this.$message.error("请求发送失败");
		 	})	 		
		 	},
		// 关闭对话框之前做的动作
		handleClose(done){
		    // 清空上一次表单数据
		    this.addCustDataForm.ptype='';
		    this.addCustDataForm.cprice='';
		    this.addCustDataForm.bz='';
			this.addCustDataForm.ptime='';
			
		    // 关闭对话框
		    done();
		},
		// 提交对话框添加客户信息
		
        // 向后端发起异步请求，查询所有的客户信息
      customerList(){
					this.$axios.get('/money/all')
					        .then((resp)=>{
					            // 获取后端返回给前端的信息
					            if (resp.data.message == 'success') {
									 // this.$message.error("向后端发起客户列表请求成功");
					                // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
					                this.carLists = resp.data.data.moneyLists;
					            }
					        }).catch((error)=>{
					            this.$message.error("所选时段未查找到收支信息，暂不可汇总");
					            console.log(error);
					        })
					    }
					},
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.customerList()
    },
  }
</script>
