import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Index from '@/views/Index'
import Customer from '@/views/Customer'
import Worker from '@/views/Worker'
import Salary from '@/views/Salary'
import Register from '@/views/Register'
import Test from  '@/views/Test'
import Car from  '@/views/Car'
import Oil from  '@/views/Oil'
import Bank from  '@/views/Bank'
import Paycom from  '@/views/Paycom'
import PayCar from  '@/views/PayCar'
import Payemp from  '@/views/Payemp'
import Money from '@/views/Money'
import Count from '@/views/Count.vue'
import Bankcard from '@/views/Bankcard'
import Oillist from '@/views/Oillist'
import Danwei from '@/views/Danwei'
Vue.use(VueRouter)

const routes = [
  {
	  
	  path: '/',
	  name: 'Login',
	  component: Login,
 //    path: '/',
	// name: 'Index' ,
	// component: Index,
    // name: 'Login',
    // component: Login
  },
  {
    path: '/Index',
    name: 'Index' ,
    component: Index,
	
    children:[
      {
        path: '/cust/custList',
        name: 'Customer',
        component: Customer,
      },
	 
      {
        path: '/worker/workerList',
        name: 'Worker',
        component: Worker,
      },

      {
        path: '/history/salaryList',
        name: 'Money',
        component: Money,
      },
      {
        path: '/history/countList',
        name: 'Count',
        component: Count,
      },
	  {
	    path: '/ces/Test',
	    name: 'Test',
	    component: Test,
	  },
	  {
	    path: '/cust/carList',
	    name: 'Car',
	    component: Car,
	  },
	  {
	    path: '/cust/cusList',
	    name: 'Salary',
	    component: Salary,
	  },
	  {
	    path: '/cust/dwList',
	    name: 'Danwei',
	    component: Danwei,
	  },
	  {
	    path: '/oil/oilList',
	    name: 'Oil',
	    component: Oil,
	  },
	  {
	    path: '/oil/oilcard',
	    name: 'Oillist',
	    component: Oillist,
	  },
	  {
	    path: '/ces/bankList',
	    name: 'Bank',
	    component: Bank,
	  },
	  {
	    path: '/ces/bankcard',
	    name: 'Bankcard',
	    component: Bankcard,
	  },
	  {
	    path: '/pay/paycarList',
	    name: 'PayCar',
	    component: PayCar,
	  },
	  {
	    path: '/pay/payempList',
	    name: 'Payemp',
	    component: Payemp,
	  },
	  {
	    path: '/pay/paycomList',
	    name: 'Paycom',
	    component: Paycom,
	  },
    ]
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  }
  
]

const router = new VueRouter({
  routes
})

export default router
