<template>
    <div id="customer">
               
                <el-form :inline="true" :model="searchCustForm" class="demo-form-inline">
                  <el-button type="primary" @click="exportexcel">导出</el-button>
                  &nbsp;&nbsp;&nbsp;
				  <el-button type="primary" @click="preSaveCustomer">添加</el-button>
                  &nbsp;&nbsp;&nbsp;
                  <el-form-item label="员工名称">
                    <el-input v-model="searchCustForm.ename" required="true" placeholder="员工名称">
                    </el-input>				
                  </el-form-item>
				<el-form-item label="电话">
				  <el-input v-model="searchCustForm.phone" required="true" placeholder="电话">
				  </el-input>				
				</el-form-item>
				<el-form-item label="性别">
				  <input type="radio" name="radios12" value="1" v-model="searchCustForm.sex"><label>女</label>
				  <input type="radio" name="radios12" value="0" v-model="searchCustForm.sex"><label>男</label>
				  <input type="radio" name="radios12" value="" v-model="searchCustForm.sex"><label>全选</label>
				</el-form-item>
				<el-form-item label="身份证">
				  <el-input v-model="searchCustForm.sfzcard" required="true" placeholder="身份证">
				  </el-input>				
				</el-form-item>
				<el-form-item label="驾驶证">
				  <el-input v-model="searchCustForm.jszcard" required="true" placeholder="驾驶证">
				  </el-input>				
				</el-form-item>
				<el-form-item label="从业资格证">
				  <el-input v-model="searchCustForm.cyzgzcard" required="true" placeholder="从业资格证">
				  </el-input>				
				</el-form-item>
				<el-form-item>
				  <el-date-picker
				      v-model="searchCustForm.systarttime"
				      type="date"
				      placeholder="试用期开始时间">
				  </el-date-picker>
				  <el-date-picker
				      v-model="searchCustForm.syendtime"
				      type="date"
				      placeholder="试用期结束时间">
				  </el-date-picker>
				</el-form-item>
				<el-form-item >
				 <el-date-picker
				  placeholder="劳务合同开始时间"   
				 	type="date"
				  v-model="searchCustForm.lwhtstarttime">
				 </el-date-picker>	
				 <el-date-picker
				  placeholder="劳务合同开始时间"   
				 	type="date"
				  v-model="searchCustForm.lwhtendtime">
				 </el-date-picker>
				</el-form-item>
				<el-form-item label="是否有社保:">
				 <input type="radio" name="radios13" value="1" v-model="searchCustForm.sb"><label>是</label>
				<input type="radio" name="radios13" value="0" v-model="searchCustForm.sb"><label>否</label>				  
				</el-form-item>			
				<el-form-item label="商业保险单号:">
				  <el-input
				    placeholder="请输入商业保险单号"
				    maxlength="50"
				    v-model="searchCustForm.sybx"
				  />
				</el-form-item>
				
				<el-form-item >
				  <el-date-picker
				      placeholder="商业保险开始时间"
				  	type="date"
				      v-model="searchCustForm.sybxstarttime">
				  </el-date-picker>
			
				 <el-date-picker
				     placeholder="商业保险结束时间"				      
				 	type="date"
				     v-model="searchCustForm.sybxendtime">
				 </el-date-picker>
				</el-form-item>
			
                  <el-form-item>
                    <el-button type="primary" @click="searchCust">搜索</el-button>
                  </el-form-item>
                </el-form>
                <el-table
                    :data="custList"
                    stripe
                    style="width: 100%">
					
					<!-- <el-table-column
					prop="eid"
					label="员工编号"
					width="80">
					</el-table-column> -->
					<el-table-column
					label="编号"
						 type="index"
						 width="150">
					   </el-table-column>

                    <el-table-column
                    prop="ename"
                    label="姓名"
                    width="80">
                    </el-table-column>

                    <el-table-column
                    prop="phone"
                    label="电话"
                    width="120">
                    </el-table-column>

                    <el-table-column
                    prop="sex"
                    label="性别"
					width="80">
					<template slot-scope="scope">{{ scope.row.sex == 1 ? '女' : '男' }}</template>
                    </el-table-column>

                    <el-table-column
                    prop="sfzcard"
                    label="身份证"
                    width="100">
                    </el-table-column>
					
					<el-table-column
					prop="jszcard"
					label="驾驶证"
					width="100">
					</el-table-column>
					
					<el-table-column
					prop="cyzgzcard"
					label="从业资格证"
					width="150">
					</el-table-column>
					
					<el-table-column
					prop="systarttime"
					:formatter="dateFormat"
					label="试用期开始时间"
					width="100">
					</el-table-column>

					<el-table-column
					prop="syendtime"
					:formatter="dateFormat"
					label="试用期结束时间"
					width="100">
					</el-table-column>

					
					<el-table-column
					prop="lwht"
					label="是否有劳务合同"
					width="100">
					<template slot-scope="scope">{{ scope.row.lwht === 1 ? '是' : '否' }}</template>
					</el-table-column>
					
					<el-table-column
					prop="lwhtstarttime"
					:formatter="dateFormat"
					label="劳务合同开始时间"
					width="100">
					</el-table-column>
					
					<el-table-column
					prop="lwhtendtime"
					:formatter="dateFormat"
					label="劳务合同结束时间"
					width="100">
					</el-table-column>
					
					
					<el-table-column
					prop="sb"
					label="是否有社保">
					<template slot-scope="scope">{{ scope.row.sb === 1 ? '是' : '否' }}</template>
					</el-table-column>
                   
					<el-table-column
					prop="sybx"
					label="商业保险单号"
					width="120">
					</el-table-column>
					
					<el-table-column
					prop="sybxstarttime"
					:formatter="dateFormat"
					label="商业保险开始时间"
					width="100">
					</el-table-column>
					
					<el-table-column
					prop="sybxendtime"
					:formatter="dateFormat"
					label="商业保险结束时间"
					width="100">
					</el-table-column>



                    <el-table-column
                    label="操作" width="200px">
                    <template slot-scope="scope">
                      <!-- 删除按钮 -->
                        <el-button type="primary" 
                        icon="el-icon-delete" 
                        @click="deleteCust(scope.row.eid)">
                        </el-button>
                      <!-- 修改按钮 -->
                        <el-button type="danger" 
                        icon="el-icon-edit" 
                        @click="preUpdateCust(scope.row.eid)">
                        </el-button>
                    </template>
                </el-table-column>
                </el-table>
                <!-- 
                    “添加送水工”对话框
                    addBox是一个标志变量：true显示对话框，否则隐藏对话框
                    handleClose：对话框关闭之前执行的动作
                    addCustDataForm是一个表单，用来存放要添加的客户数据
                 -->
        <el-dialog
        title="添加员工"
        :visible.sync="addBox"
        width="50%"
        :before-close="handleClose"
       >
        <el-form
		:rules="rules"
          ref="addCustDataForm"
          label-width="150px"
          v-model="addCustDataForm"
        >
          <el-form-item label="**员工姓名:" prop="ename">
            <el-input
              placeholder="请输入员工姓名"
              maxlength="50"
              v-model="addCustDataForm.ename"
            />
          </el-form-item>

          <el-form-item label="员工电话:">
            <el-input
              placeholder="请输入员工电话"
              maxlength="50"
              v-model="addCustDataForm.phone"
            />
          </el-form-item>
          <el-form-item label="员工性别:">
			  <input type="radio" name="radios11" value="1" v-model="addCustDataForm.sex"><label>女</label>
			  <input type="radio" name="radios11" value="0" v-model="addCustDataForm.sex"><label>男</label>
            
          </el-form-item>

          <el-form-item label="身份证:">
            <el-input
              placeholder="请输入身份证"
              maxlength="50"
              v-model="addCustDataForm.sfzcard"
            />
          </el-form-item>
		  
		  <el-form-item label="驾驶证:">
		    <el-input
		      placeholder="请输入驾驶证"
		      maxlength="50"
		      v-model="addCustDataForm.jszcard"
		    />
		  </el-form-item>
		  <el-form-item label="从业资格证:">
		    <el-input
		      placeholder="请输入从业资格证"
		      maxlength="150"
		      v-model="addCustDataForm.cyzgzcard"
		    />
		  </el-form-item>
		  
		  <el-form-item label="试用期开始时间:">
		    <el-date-picker
		        placeholder="试用期开始时间"
		         
		    		 type="date"
		        v-model="addCustDataForm.systarttime">
		    </el-date-picker>
		  </el-form-item>
		  
		  <el-form-item label="试用期结束时间:">
		   <el-date-picker
		       placeholder="试用期结束时间"
		        
		   		type="date"
		       v-model="addCustDataForm.syendtime">
		   </el-date-picker>
		  </el-form-item>
		  
		  <el-form-item label="是否有劳务合同:">
			  <input type="radio" name="radios1" value="1" v-model="addCustDataForm.lwht"><label>是</label>
			  <input type="radio" name="radios1" value="0" v-model="addCustDataForm.lwht"><label>否</label>
		   
		  </el-form-item>
		  
		  <el-form-item label="劳务合同开始时间:">
		   <el-date-picker
		    placeholder="劳务合同开始时间"   
		   	type="date"
		    v-model="addCustDataForm.lwhtstarttime">
		   </el-date-picker>
		  </el-form-item>
		  
		  <el-form-item label="劳务合同结束时间:">
		   <el-date-picker
		    placeholder="劳务合同开始时间"   
		   	type="date"
		    v-model="addCustDataForm.lwhtendtime">
		   </el-date-picker>
		  </el-form-item>
		  
		  <el-form-item label="是否有社保:">
			  <input type="radio" name="radios2" value="1" v-model="addCustDataForm.sb"><label>是</label>
			  <input type="radio" name="radios2" value="0" v-model="addCustDataForm.sb"><label>否</label>
		    
		  </el-form-item>
		  
		  <el-form-item label="商业保险单号:">
		    <el-input
		      placeholder="请输入商业保险单号"
		      maxlength="50"
		      v-model="addCustDataForm.sybx"
		    />
		  </el-form-item>
		  
		  <el-form-item label="商业保险开始时间:">
		    <el-date-picker
		        placeholder="商业保险开始时间"
		         
		    				   type="date"
		        v-model="addCustDataForm.sybxstarttime">
		    </el-date-picker>
		  </el-form-item>
		  
		  <el-form-item label="商业保险结束时间:">
		   <el-date-picker
		       placeholder="商业保险结束时间"
		        
		   				   type="date"
		       v-model="addCustDataForm.sybxendtime">
		   </el-date-picker>
		  </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveCustomer">确 定</el-button>
        </span>
      </el-dialog>
<!-- 
  修改客户对话框 
  updateBox表示修改对话框显示和隐藏的标志：true显示，false隐藏
  updateHandleClose表示关闭窗体之前发生的动作
-->
      <el-dialog
        title="修改员工"
        :visible.sync="updateBox"
        width="50%"
        :before-close="updateHandleClose"
       >
        <el-form
          ref="updateCustDataForm"
          label-width="150px"
          v-model="updateCustDataForm"
        >
          <el-form-item label="员工姓名:">
            <el-input
              placeholder="请输入员工姓名"
              maxlength="50"
              v-model="updateCustDataForm.ename"
            />
          </el-form-item>
          
          <el-form-item label="员工电话:">
            <el-input
              placeholder="请输入员工电话"
              maxlength="50"
              v-model="updateCustDataForm.phone"
            />
          </el-form-item>
          <el-form-item label="员工性别:">
			  <input type="radio" name="radios12" value="1" v-model="updateCustDataForm.sex"><label>女</label>
			  <input type="radio" name="radios12" value="0" v-model="updateCustDataForm.sex"><label>男</label>
			              
            
          </el-form-item>
          
          <el-form-item label="身份证:">
            <el-input
              placeholder="请输入身份证"
              maxlength="50"
              v-model="updateCustDataForm.sfzcard"
            />
          </el-form-item>
          
          <el-form-item label="驾驶证:">
            <el-input
              placeholder="请输入驾驶证"
              maxlength="50"
              v-model="updateCustDataForm.jszcard"
            />
          </el-form-item>
          <el-form-item label="从业资格证:">
            <el-input
              placeholder="请输入从业资格证"
              maxlength="50"
              v-model="updateCustDataForm.cyzgzcard"
            />
          </el-form-item>
          
          <el-form-item label="试用期开始时间:">
			  <el-date-picker
			      placeholder="试用期开始时间"
			       
				   type="date"
			      v-model="updateCustDataForm.systarttime">
			  </el-date-picker>
            
          </el-form-item>
          
          <el-form-item label="试用期结束时间:">
			  <el-date-picker
			      placeholder="试用期结束时间"
			       
			  		type="date"
			      v-model="updateCustDataForm.syendtime">
			  </el-date-picker>
            
          </el-form-item>
          
          <el-form-item label="是否有劳务合同:">
			 
            <input type="radio" name="radios3" value="1" v-model="updateCustDataForm.lwht"><label>是</label>
            <input type="radio" name="radios3" value="0" v-model="updateCustDataForm.lwht"><label>否</label>
          </el-form-item>
          
          <el-form-item label="劳务合同开始时间:">
			  <el-date-picker
			      placeholder="劳务合同开始时间"
			       
			  	type="date"
			      v-model="updateCustDataForm.lwhtstarttime">
			  </el-date-picker>
        
          </el-form-item>
          
          <el-form-item label="劳务合同结束时间:">
			  <el-date-picker
			      placeholder="劳务合同结束时间"
			       
			  				   type="date"
			      v-model="updateCustDataForm.lwhtendtime">
			  </el-date-picker>
            
          </el-form-item>
          
          <el-form-item label="是否有社保:">
            <input type="radio" name="radios4" value="1" v-model="updateCustDataForm.sb"><label>是</label>
            <input type="radio" name="radios4" value="0" v-model="updateCustDataForm.sb"><label>否</label>
          </el-form-item>
          
          <el-form-item label="商业保险单号:">
            <el-input
              placeholder="请输入商业保险单号"
              maxlength="50"
              v-model="updateCustDataForm.sybx"
            />
          </el-form-item>
          
          <el-form-item label="商业保险开始时间:">
			  <el-date-picker
			      placeholder="商业保险开始时间"
			       
			  				   type="date"
			      v-model="updateCustDataForm.sybxstarttime">
			  </el-date-picker>
           
          </el-form-item>
          
          <el-form-item label="商业保险结束时间:">
			  <el-date-picker
			      placeholder="商业保险结束时间"
			       
			  				   type="date"
			      v-model="updateCustDataForm.sybxendtime">
			  </el-date-picker>
           
          </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="updateCustomer">确 定</el-button>
        </span>
      </el-dialog>
      <el-pagination v-if="allCustomer"
				    background
				    layout="sizes,prev, pager, next,jumper,->,total"
				    :page-size.sync="pageSize"
				    :current-page.sync="pageNum"
				    :page-sizes="[3,5,10]"
				    :total="total"
				    :hide-on-single-page="true"
				    @current-change="customerList"
				    @size-change="customerList" >
				</el-pagination>
				<el-pagination v-if="selectCustomer"
				    background
				    layout="sizes,prev, pager, next,jumper,->,total"
				    :page-size.sync="pageSize"
				    :current-page.sync="pageNum"
				    :page-sizes="[3,5,10]"
				    :total="total"
				    :hide-on-single-page="true"
				    @current-change="searchCust"
				    @size-change="searchCust" >
				</el-pagination>
    </div>
</template>

<script>
import moment from "moment"
  export default {
	  
	 
    data() {
      return {
		//   rules: {
		//     ename: [
		//       { required: true, message: '请输入姓名', trigger: 'blur' }
			
		//     ],
		//  	},
        // 存储后端返回给前端的客户列表
        custList: [],
        // “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
        addBox:false,
        allCustomer:true,
        // “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
        updateBox:false,
        selectCustomer:false,
        // “添加客户”对话框的表单
		
        addCustDataForm:{
			eid:'',
            ename:'',
            phone:'',
            sex:'',
            sfzcard:'',
            jszcard:'',
            cyzgzcard: '',
			systarttime:'',
			syendtime:'',
			lwht:'',
			lwhtstarttime:'',
			lwhtendtime:'',
			sb: '',
			sybx:'',
			sybxstarttime:'',
			sybxendtime:''
			
        },
        // “修改客户”对话框的表单
        updateCustDataForm:{
			eid:'',
            ename:'',
            phone:'',
            sex:'',
            sfzcard:'',
            jszcard:'',
            cyzgzcard: '',
            systarttime:'',
            syendtime:'',
            lwht:'',
            lwhtstarttime:'',
            lwhtendtime:'',
            sb: '',
            sybx:'',
            sybxstarttime:'',
            sybxendtime:''
        },
        // 搜索表单
        searchCustForm:{
            ename:'',
			phone:'',
			sex:'',
			sfzcard:'',
			jszcard:'',
			cyzgzcard: '',
			systarttime:'',
			syendtime:'',
			lwht:'',
			lwhtstarttime:'',
			lwhtendtime:'',
			sb: '',
			sybx:'',
			sybxstarttime:'',
			sybxendtime:''
        },
        total : 0,
				pageNum : 1,
				pageSize : 10,
      }
    },
    methods: {
         dateFormat(row,column){
           let date = row[column.property];
           if(date === undefined){
             return ''
           } ;
           return moment(date).format("yyyy-MM-DD")
         },
		 //导出
		 exportexcel(){
		 			 this.$alert(
		 			         <div class="el-message-box--custom-body">
		 			           <div style="font-size:16px">确认导出所有数据？</div>
		 			         </div>,
		 			         "数据导出",
		 			         {
		 			           showCancelButton: true,				          
		 			           customClass: "el-message-box--noicon el-message-box--custom",
		 			           confirmButtonText: "导出"
		 			         }
		 			       ).then(() => {
		 			         window.open('http://localhost:8099/emm/export', "_blank");
		 					 // window.open('/car/export', "_blank");
		 			       });			 	 
		   
		 },
		 // 收搜
		 searchCust(){
		 	console.log("11"); 	
		 	this.$axios.post('/emm/search/'+this.pageNum+'/'+this.pageSize,
	
			{
				ename: this.searchCustForm.ename,
				phone: this.searchCustForm.phone,
				sex: this.searchCustForm.sex,
				sfzcard: this.searchCustForm.sfzcard,
				jszcard: this.searchCustForm.jszcard,
				cyzgzcard: this.searchCustForm.cyzgzcard,
				systarttime: this.searchCustForm.systarttime,
				syendtime: this.searchCustForm.syendtime,
				lwhtstarttime: this.searchCustForm.lwhtstarttime,
				lwhtendtime: this.searchCustForm.lwhtendtime,
				sb: this.searchCustForm.sb,
				sybx: this.searchCustForm.sybx,
				sybxstarttime: this.searchCustForm.sybxstarttime,
				sybxendtime: this.searchCustForm.sybxendtime
			})
		 	.then((resp) =>{
		 	if(resp.data.data.empList != null){
		 			this.custList = resp.data.data.empList.list;
		 			this.total = resp.data.data.empList.total;
		 			this.pageNum = resp.data.data.empList.pageNum;
		 			this.allCustomer = false;
		 			this.selectCustomer = true;
		 			// this.$message.success("查询成功");
		 		}else
		 		{
		 			this.$message.error("没有相关用户信息");
		 		}
		 	}).catch((error) =>{
		 		console.log(error);
		 		this.$message.error("请求发送失败");
		 	})
		 		
		 	},
		// 修改之前的数据回显
		preUpdateCust(eid) {
		   this.$axios.get('/emm/getemm/'+eid)
		   .then((resp)=>{
		      // 条件成立：表示能够从后端获取对应的客户信息，完成数据回显
		      if (resp.data.message == 'success') {
		        // 显示“修改客户”对话框
		        this.updateBox = true;
		        // 将后端的客户信息赋值给前端定义的修改表单
		        this.updateCustDataForm = resp.data.data.emmById;
		      } else {
		        this.$message.error("没有找到对应的客户信息");
		      }
		   })
		   .catch((error)=>{
		      this.$message.error("修改客户信息数据回显请求发送失败");
		      console.log(error);
		   })
		},
		// 修改客户信息
		updateCustomer(){
		    this.$axios.post('/emm/update',{
		      eid: this.updateCustDataForm.eid,
		      ename: this.updateCustDataForm.ename,
		      phone: this.updateCustDataForm.phone,
		      sex: this.updateCustDataForm.sex,
		      sfzcard: this.updateCustDataForm.sfzcard,
			  jszcard: this.updateCustDataForm.jszcard,
			  cyzgzcard: this.updateCustDataForm.cyzgzcard,
			  systarttime: this.updateCustDataForm.systarttime,
			  syendtime: this.updateCustDataForm.syendtime,
			  lwht: this.updateCustDataForm.lwht,
			  lwhtstarttime: this.updateCustDataForm.lwhtstarttime,
			  lwhtendtime: this.updateCustDataForm.lwhtendtime,
			  sb: this.updateCustDataForm.sb,
			  sybx: this.updateCustDataForm.sybx,
			  sybxstarttime: this.updateCustDataForm.sybxstarttime,
			  sybxendtime: this.updateCustDataForm.sybxendtime
		    }).then((resp)=>{
		      // 条件成立：修改成功，否则修改失败
		      if (resp.data.code == 20000) {
		         this.$message.success("修改成功");
		      } else {
		         this.$methods.error(resp.data.message);
		      }
		      // 隐藏对话框
		      this.updateBox = false;
		      // 重新查询修改之后的客户信息
		      this.customerList();
		    }).catch((error)=>{
		       this.$message.error("修改客户信息请求发送失败");
		       console.log(error);
		    })
		    
		},
		// 根据客户ID删除客户信息
		deleteCust(eid){
		    this.$confirm("确定要删除吗","提示",{
		        confirmButtonText:'确定',
		        cannelButtonText:'取消',
		        type:'警告'
		    })
		    // 点击确定执行删除操作
		    .then(()=>{
		          // 删除操作之前先做check(验证当前客户有没有管理送水历史，如果有提示员工该客户有
		          // 对应的送水历史关联)
		          this.$axios.post('/emm/delete/'+eid)
		          // then方法的resp参数封装了后端返回给前端的数据
		                    .then((resp)=>{
		                        // 条件成立：表示该客户有对应的送水历史关联，阻止删除
		                       if(resp.data.code == 20000) {
		                               this.$message.success("删除成功");
		                           } else {
		                               this.$message.error(resp.data.message);
		                           }
		                           this.customerList();
		                    }).catch((error)=>{
                                    this.$message.error("删除客户信息请求发送失败");
                                    console.log(error);
                                })
							
		          })
		},
		// 预添加客户信息，弹出“添加客户”对话框
		preSaveCustomer(){
		    this.addBox = true;
		
		},
		// 关闭对话框之前做的动作
		handleClose(done){
		    // 清空上一次表单数据
		     this.addCustDataForm.eid='';
		     this.addCustDataForm.ename='';
		     this.addCustDataForm.phone='';
		     this.addCustDataForm.sex='';
		     this.addCustDataForm.sfzcard='';
		     this.addCustDataForm.jszcard='';
		     this.addCustDataForm.cyzgzcard='';
		     this.addCustDataForm.systarttime='';
		     this.addCustDataForm.syendtime='';
		     this.addCustDataForm.lwht='';
		     this.addCustDataForm.lwhtstarttime='';
			 this.addCustDataForm.lwhtendtime='';
		     this.addCustDataForm.sb='';
		     this.addCustDataForm.sybx='';
		     this.addCustDataForm.sybxstarttime='';
		     this.addCustDataForm.sybxendtime='';
		    // 关闭对话框
		    done();
		},
		// 提交对话框添加客户信息
		saveCustomer(){
		    this.$axios.post('/emm/insert',{
		        // 前端使用JSON格式将表单参数传入给后端
		       eid: this.addCustDataForm.eid,
		       ename: this.addCustDataForm.ename,
		       phone: this.addCustDataForm.phone,
		       sex: this.addCustDataForm.sex,
		       sfzcard: this.addCustDataForm.sfzcard,
		       jszcard: this.addCustDataForm.jszcard,
		       cyzgzcard: this.addCustDataForm.cyzgzcard,
		       systarttime: this.addCustDataForm.systarttime,
		       syendtime: this.addCustDataForm.syendtime,
		       lwht: this.addCustDataForm.lwht,
		       lwhtstarttime: this.addCustDataForm.lwhtstarttime,
			   lwhtendtime: this.addCustDataForm.lwhtendtime,
		       sb: this.addCustDataForm.sb,
		       sybx: this.addCustDataForm.sybx,
		       sybxstarttime: this.addCustDataForm.sybxstarttime,
		       sybxendtime: this.addCustDataForm.sybxendtime
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error(resp.data.message);
		        }
		        // 重新查询客户信息
		        this.customerList();
		        // 关闭对话框
		        this.addBox = false;
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
        // 向后端发起异步请求，查询所有的客户信息
      customerList(){
					this.$axios.get('/emm/allemm/'+this.pageNum+"/"+this.pageSize)
					        .then((resp)=>{
					            // 获取后端返回给前端的信息
					            if (resp.data.message == 'success') {
									 // this.$message.error("向后端发起客户列表请求成功");
					                // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
					                this.custList = resp.data.data.empList.list;
									this.total = resp.data.data.empList.total;
									this.pageNum = resp.data.data.empList.pageNum;
									this.allCustomer = true;
									this.selectCustomer = false;
					            }
					        }).catch((error)=>{
					            this.$message.error("向后端发起客户列表请求失败");
					            console.log(error);
					        })
					    }
					},
					
		
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.customerList()
    },
  }
</script>
