<template>
    <div id="login">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" 
          class="demo-ruleForm" >
            <el-form-item label="用户名" prop="userName">
              <el-input type="text" v-model="ruleForm.userName"></el-input>
            </el-form-item>

            <el-form-item label="密码" prop="userPwd">
              <el-input type="password" v-model="ruleForm.userPwd"></el-input>
            </el-form-item>

			<el-form-item label="手机号" prop="userMobile">
              <el-input type="text" v-model="ruleForm.userMobile"></el-input>
            </el-form-item>

            <el-form-item label="邮箱" prop="userEmail">
              <el-input type="text" v-model="ruleForm.userEmail"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm()">注册</el-button>
              <el-button @click="resetForm()">重置</el-button>
			  <el-button @click="gotoLogin()">登陆</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>


<script>

  export default {
    data() {
      return {
        ruleForm: {
          userName: '',
          userPwd: '' ,
		  userMobile: '',
		  userEmail: ''
        },
        rules: {
          userName: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          userPwd: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' },
			],
		  userMobile: [
		    { required: false, message: '请输入手机号' },
		  ],
		  userEmail: [
		    { required: false, message: '请输入邮箱' },
		    
          ]
        }
      };
    },
    methods: {
        // 提交表单
      submitForm() {
        // 表单验证
        this.$refs['ruleForm'].validate((valid) => {         
          // 条件成立：表单验证成功，提交表单向后端发起注册请求
          if (valid) {      
			  //向后端发起异步请求，进行注册
			  this.$axios.post('http://localhost:8097/account/register',
			  {userName:this.ruleForm.userName, 
			  userPwd:this.ruleForm.userPwd,
              userMobile:this.ruleForm.userMobile,
              userEmail:this.ruleForm.userEmail})
			  //then(resp)方法是一个回调方法，用来接受后端返回的结果，resp封装了后端返回给前端的全部数据
			 .then((resp)=>{
				 let msg = resp.data.responseData;
			     // 接收后端返回给前端的数据
			     // 条件成立：表示注册成功，
			     if (resp.data.responseMessage == 'registerSuccess') {
			         this.$message.success("注册成功");
			         this.$router.push({name:"Login"});
			     } else {
			         this.$message.error(msg);
			     }
			 })
			 // catch(error) 也是一个回调方法，当前端请求没有到达后端就会调用此方法
			 // error封装了异常信息
			 .catch((error)=>{
			     this.$message.error("注册请求发送失败");
			     console.log(error);})
          }
        });
      },
      // 重置表单
      resetForm() {
        this.$refs['ruleForm'].resetFields();
      },
	  gotoLogin(){
		  this.$router.push({name:"Login"});
	  }
    }
  }
</script>

<style>
  .demo-ruleForm{
    max-width:500px;
    margin:0px auto;
  }

  h1{
      text-align: center;
  }
  #login{
      margin: 50px auto;
  }
</style>

