<template>
	
  <div class="loginbody">
    <div class="logindata">
      <div class="logintext">
        <h2>静捷物流管理系统</h2>
      </div>
      <div class="formdata">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
          <el-form-item prop="username">
            <el-input v-model="ruleForm.username" clearable placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="ruleForm.password" clearable placeholder="请输入密码" show-password></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="tool">
        <div>
          <el-checkbox v-model="checked" @change="remenber">记住密码</el-checkbox>
        </div>
        <div>
          <span class="shou" @click="forgetpas">忘记密码？</span>
        </div>
      </div> -->
      <div class="butt">
        <el-button type="primary" @click="submitForm()">登录</el-button>
        <!-- <el-button class="shou" @click="register">注册</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "login",
  data() {
    return {
      ruleForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ]
      }
    };
  },
  // mounted() {
  //   if (localStorage.getItem("news")) {
  //     this.form = JSON.parse(localStorage.getItem("news"))
  //     this.checked = true
  //   }
  // },
  methods: {
    submitForm() {// 提交表单
      // 表单验证
      this.$refs['ruleForm'].validate((valid) => {
        // 条件成立：表单验证成功，提交表单向后端发起登录请求
        if (valid) {
          // 向后端发起异步请求,进行登录(前端调用后端的API)
          this.$axios.post('/user/login', {
            username: this.ruleForm.username,
            password: this.ruleForm.password
          })
            // then(resp)方法是一个回调方法，用来接收后端返回的结果。resp封装了后端返回给前端的所有数据
            .then((resp) => {
              // 接收后端返回给前端的数据
              // 条件成立：表示登录成功，跳转到“送水工管理系统”主页面
              // 前端resp.data等价于够短的CommonResult对象
              if (resp.data.message == 'success') {
                this.$message.success("登录成功");
                this.$router.push({ name: "Worker" });
              } else {
                this.$message.error("用户名或密码错误");
              }
            })
            // catch(error) 也是一个回调方法，当前端请求没有到达后端就会调用此方法
            // error封装了异常信息
            .catch((error) => {
              this.$message.error("登录请求发送失败");
              console.log(error);

            })
        }
      });
    },
    // 重置表单
    resetForm() {
      this.$refs['ruleForm'].resetFields();
    },
    gotoRegister() {
      this.$router.push({ name: "Register" });
    }
  }
};
</script>

<style scoped>
.loginbody {
  width: 100%;
  height: 100%;
  min-width: 1000px;
  background-image: url("../images/429657.jpg");
  background-size: 100% 100%;
  background-position: center center;
  overflow: auto;
  background-repeat: no-repeat;
  position: fixed;
  line-height: 100%;
  padding-top: 150px;
}

.logintext {
  margin-bottom: 20px;
  line-height: 50px;
  text-align: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

.logindata {
  width: 400px;
  height: 300px;
  transform: translate(-50%);
  margin-left: 50%;
}

.tool {
  display: flex;
  justify-content: space-between;
  color: #606266;
}

.butt {
  margin-top: 10px;
  text-align: center;
}

.shou {
  cursor: pointer;
  color: #606266;
}

/*ui*/
/* /deep/ .el-form-item__label {
  font-weight: bolder;
  font-size: 15px;
  text-align: left;
}

/deep/ .el-button {
  width: 100%;
  margin-bottom: 10px;

} */
</style>

