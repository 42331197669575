<template>
	<div id="customer">

		<el-form :inline="true" :model="searchCustForm" class="demo-form-inline">
			<el-button type="primary" @click="exportexcel">导出</el-button>
			&nbsp;&nbsp;&nbsp;
			<el-button type="primary" @click="preSaveCustomer">添加</el-button>
			&nbsp;&nbsp;&nbsp;
			<el-form-item label="运单编号">
				<el-input v-model="searchCustForm.yid" required="true" placeholder="运单编号">
				</el-input>
			</el-form-item>

			<el-form-item label="司机姓名:">
					<el-select v-model="value13" placeholder="请选择" @change="getEid">
						<el-option label="全选" value="">
						</el-option>
						<el-option v-for="item in emms" :key="item.eid" :label="item.ename" :value="item.eid">
						</el-option>
					</el-select>
				</el-form-item>
			<el-form-item label="车牌号:">
				<el-select v-model="value14" placeholder="请选择" @change="getCid">
					<el-option label="全选" value="">
					</el-option>
					<el-option v-for="item in cars" :key="item.cid" :label="item.carone" :value="item.cid">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="时间:">
				<el-date-picker placeholder="请选择时间" type="date" v-model="searchCustForm.ytime">
				</el-date-picker>
			
			</el-form-item>
			<el-form-item label="委托单位:">
				<el-input v-model="searchCustForm.wtdw" required="true" placeholder="委托单位">
				</el-input>		
			</el-form-item>
			
			<el-form-item label="结算单位:">
				<el-input v-model="searchCustForm.jsdw" required="true" placeholder="结算单位">
				</el-input>					
			</el-form-item>
			<el-form-item label="开始地点:">
				<el-input placeholder="请输入开始地点" maxlength="50" v-model="searchCustForm.placestart" />
			</el-form-item>
			
			<el-form-item label="终止地点:">
				<el-input placeholder="请输入终止地点" maxlength="50" v-model="searchCustForm.placeend" />
			</el-form-item>			

			<el-form-item label="价格备注:">
					<el-input placeholder="请输入价格备注" maxlength="50" v-model="searchCustForm.jgbz" />
				</el-form-item>

				<el-form-item label="价格是否含税:">
					<input type="radio" name="radios11" value="1" v-model="searchCustForm.jghs"><label>是</label>
					<input type="radio" name="radios11" value="0" v-model="searchCustForm.jghs"><label>否</label>
					<input type="radio" name="radios11" value="" v-model="searchCustForm.jghs"><label>全选</label>
				</el-form-item>

				<el-form-item label="运单发票号:">
					<el-input placeholder="请输入运单发票号" maxlength="50" v-model="searchCustForm.bzfqhm" />
				</el-form-item>
			<el-form-item label="代垫费发票号:">
					<el-input placeholder="请输入代垫费发票号" maxlength="50" v-model="searchCustForm.ddffp" />
				</el-form-item>
				
				<el-form-item label="代垫费是否支付:">
					<input type="radio" name="radios12" value="1" v-model="searchCustForm.isddf"><label>是</label>
					<input type="radio" name="radios12" value="0" v-model="searchCustForm.isddf"><label>否</label>		
					<input type="radio" name="radios12" value="" v-model="searchCustForm.isddf"><label>全选</label>	
				</el-form-item>
				
				<el-form-item label="回单回收:">
					<input type="radio" name="radios13" value="1" v-model="searchCustForm.hdhs"><label>是</label>
					<input type="radio" name="radios13" value="0" v-model="searchCustForm.hdhs"><label>否</label>
					<input type="radio" name="radios13" value="" v-model="searchCustForm.hdhs"><label>全选</label>
				</el-form-item>
				
				<el-form-item label="运单金额是否支付:">
					<input type="radio" name="radios14" value="1" v-model="searchCustForm.isydje"><label>是</label>
					<input type="radio" name="radios14" value="0" v-model="searchCustForm.isydje"><label>否</label>
					<input type="radio" name="radios14" value="" v-model="searchCustForm.isydje"><label>全选</label>	
				</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="searchCust">搜索</el-button>
			</el-form-item>

			<el-form-item label="选中项目代垫费合计：" >	
			{{sum}}
			</el-form-item>
		</el-form>
		<!-- :data="custList" -->
		<!-- :data='custList.slice((page - 1) * pageSize, page * pageSize)' @sort-change="sortChange" -->
		<el-table :data="custList" height="1000" max-height="1000"
			:default-sort="{ prop: 'ytime', order: 'descending' }" highlight-current-row strip
			style="width: 100%">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column fixed="left" prop="yid" label="编号" width="100" sortable>
			</el-table-column>		
			<el-table-column prop="ename" label="司机姓名" width="100" sortable>
			</el-table-column>

			<el-table-column prop="carone" label="车牌号" width="120" sortable>
			</el-table-column>

			<el-table-column prop="ytime" :formatter="dateFormat" sortable label="时间" width="100">
			</el-table-column>

			<el-table-column prop="wtdw" label="委托单位" width="120" sortable>
			</el-table-column>

			<el-table-column prop="jsdw" label="结算单位" width="120" sortable>
			</el-table-column>


			<el-table-column prop="palcestart" label="开始地点" width="120" sortable>
			</el-table-column>

			<el-table-column prop="palceend" label="终止地点" width="120" sortable>
			</el-table-column>

			<el-table-column prop="dw" label="吨位" width="100" sortable>
			</el-table-column>

			<el-table-column prop="dj" label="单价" width="100" sortable>
			</el-table-column>

			<el-table-column prop="zj" :formatter="rounding" label="运费金额" width="100" sortable>
			</el-table-column>


			<el-table-column prop="jgbz" label="价格备注" width="150">
			</el-table-column>

			<el-table-column prop="jghs" label="价格是否含税" width="100">
				<template slot-scope="scope">{{ scope.row.jghs == 1 ? '是' : '否' }}</template>
			</el-table-column>

			<el-table-column prop="bzfqhm" label="运单发票号" width="150">
			</el-table-column>

			<el-table-column prop="ddf" label="代垫费" width="100">
			</el-table-column>

			<el-table-column prop="ddffp" label="代垫费发票号" width="100">
			</el-table-column>

			<el-table-column prop="ddfbz" label="代垫费备注" width="200">
			</el-table-column>

			<el-table-column prop="isddf" label="代垫费是否支付" width="100">
				<template slot-scope="scope">{{ scope.row.isddf == 1 ? '是' : '否' }}</template>
			</el-table-column>


			<el-table-column prop="hdhs" label="回单是否回收" width="100">
				<template slot-scope="scope">{{ scope.row.hdhs == 1 ? '是' : '否' }}</template>
			</el-table-column>

			<el-table-column prop="tc" label="提成" width="100" sortable>
			</el-table-column>

			<el-table-column prop="ztc" :formatter="rounding" label="总提成" width="100">
			</el-table-column>

			<el-table-column prop="isydje" label="运单金额是否支付" width="100" sortable>
				<template slot-scope="scope">{{ scope.row.isydje == 1 ? '是' : '否' }}</template>
			</el-table-column>



			<el-table-column fixed="right" label="操作" width="150px" align="center">
				<template slot-scope="scope">
					<!-- 删除按钮 -->
					<el-button type="primary" icon="el-icon-delete" @click="deleteCust(scope.row.yid)">
					</el-button>
					<!-- 修改按钮 -->
					<el-button type="danger" icon="el-icon-edit" @click="preUpdateCust(scope.row.yid)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 
                    “添加送水工”对话框
                    addBox是一个标志变量：true显示对话框，否则隐藏对话框
                    handleClose：对话框关闭之前执行的动作
                    addCustDataForm是一个表单，用来存放要添加的客户数据
                 -->
		<el-dialog title="添加记录" :visible.sync="addBox" width="50%" :before-close="handleClose">
			<el-form ref="addCustDataForm" label-width="150px" v-model="addCustDataForm">
				<el-form-item label="司机姓名:">
					<el-select v-model="value3" placeholder="请选择" @change="getEid">
						<el-option v-for="item in emms" :key="item.eid" :label="item.ename" :value="item.eid">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="车牌号:">
					<el-select v-model="value4" placeholder="请选择" @change="getCid">
						<el-option v-for="item in cars" :key="item.cid" :label="item.carone" :value="item.cid">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="时间:">
					<el-date-picker placeholder="请选择时间" type="date" v-model="addCustDataForm.ytime">
					</el-date-picker>

				</el-form-item>

				<el-form-item label="委托单位:">
					<el-select v-model="value5"  filterable placeholder="请选择" @change="getWtdw">
						<el-option v-for="item in wtdws" :key="item.dname" :label="item.dname" :value="item.dname">
						</el-option>
					</el-select>			
				</el-form-item>

				<el-form-item label="结算单位:">
					<el-select v-model="value6" filterable placeholder="请选择" @change="getJsdw">
						<el-option v-for="item in wtdws" :key="item.dname" :label="item.dname" :value="item.dname">
						</el-option>
					</el-select>				
				</el-form-item>
				<el-form-item label="开始地点:">
					<el-input placeholder="请输入开始地点" maxlength="50" v-model="addCustDataForm.placestart" />
				</el-form-item>

				<el-form-item label="终止地点:">
					<el-input placeholder="请输入终止地点" maxlength="50" v-model="addCustDataForm.placeend" />
				</el-form-item>

				<el-form-item label="吨位:">
					<el-input placeholder="请输入吨位" maxlength="50" v-model="addCustDataForm.dw" />
				</el-form-item>

				<el-form-item label="单价:">
					<el-input placeholder="请输入单价" maxlength="50" v-model="addCustDataForm.dj" />
				</el-form-item>
				<el-form-item label="运费金额:">
					{{ addCustDataForm.dw * addCustDataForm.dj }}
				</el-form-item>

				<el-form-item label="价格备注:">
					<el-input placeholder="请输入价格备注" maxlength="50" v-model="addCustDataForm.jgbz" />
				</el-form-item>

				<el-form-item label="价格是否含税:">
					<input type="radio" name="radios1" value="1" v-model="addCustDataForm.jghs"><label>是</label>
					<input type="radio" name="radios1" value="0" v-model="addCustDataForm.jghs"><label>否</label>
				</el-form-item>

				<el-form-item label="运单发票号:">
					<el-input placeholder="请输入运单发票号" maxlength="50" v-model="addCustDataForm.bzfqhm" />
				</el-form-item>
				<el-form-item label="代垫费:">
					<el-input placeholder="请输入代垫费" maxlength="50" v-model="addCustDataForm.ddf" />
				</el-form-item>
				<el-form-item label="代垫费发票号:">
					<el-input placeholder="请输入代垫费发票号" maxlength="50" v-model="addCustDataForm.ddffp" />
				</el-form-item>
				<el-form-item label="代垫费备注:">
					<el-input placeholder="请输入代垫费备注" maxlength="50" v-model="addCustDataForm.ddfbz" />
				</el-form-item>

				<el-form-item label="代垫费是否支付:">
					<input type="radio" name="radios2" value="1" v-model="addCustDataForm.isddf"><label>是</label>
					<input type="radio" name="radios2" value="0" v-model="addCustDataForm.isddf"><label>否</label>

				</el-form-item>

				<el-form-item label="回单回收:">
					<input type="radio" name="radios3" value="1" v-model="addCustDataForm.hdhs"><label>是</label>
					<input type="radio" name="radios3" value="0" v-model="addCustDataForm.hdhs"><label>否</label>

				</el-form-item>
				<el-form-item label="提成:">
					<el-input placeholder="请输入提成" maxlength="50" v-model="addCustDataForm.tc" />
				</el-form-item>


				<el-form-item label="运单金额是否支付:">
					<input type="radio" name="radios4" value="1" v-model="addCustDataForm.isydje"><label>是</label>
					<input type="radio" name="radios4" value="0" v-model="addCustDataForm.isydje"><label>否</label>

				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="saveCustomer">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 
  修改客户对话框 
  updateBox表示修改对话框显示和隐藏的标志：true显示，false隐藏
  updateHandleClose表示关闭窗体之前发生的动作
-->
		<el-dialog title="修改客户" :visible.sync="updateBox" width="50%" :before-close="updateHandleClose">
			<el-form ref="updateCustDataForm" label-width="150px" v-model="updateCustDataForm">


				<el-form-item label="司机姓名:">
					<el-select v-model="value1" placeholder="请选择" @change="getEid">
						<el-option v-for="item in emms" :key="item.eid" :label="item.ename" :value="item.eid">
						</el-option>
					</el-select>

					<!-- <el-input
              placeholder="请选择司机姓名"
              maxlength="50"
              v-model="updateCustDataForm.ename"
            /> -->
				</el-form-item>

				<el-form-item label="车牌号:">
					<el-select v-model="value2" placeholder="请选择" @change="getCid">
						<el-option v-for="item in cars" :key="item.cid" :label="item.carone" :value="item.cid">
						</el-option>
					</el-select>
					<!-- <el-input
              placeholder="请选择车牌号"
              maxlength="50"
              v-model="updateCustDataForm.carone"
            /> -->
				</el-form-item>
				<el-form-item label="时间:">
					<el-date-picker placeholder="请选择时间" type="date" v-model="updateCustDataForm.ytime">
					</el-date-picker>

				</el-form-item>

				<el-form-item label="委托单位:">
					<el-select v-model="value7" filterable placeholder="请选择" @change="getWtdw">
						<el-option v-for="item in wtdws" :key="item.dname" :label="item.dname" :value="item.dname">
						</el-option>
					</el-select>
					<!-- <el-input
              placeholder="请输入委托单位"
              maxlength="50"
              v-model="updateCustDataForm.wtdw"
            /> -->
				</el-form-item>

				<el-form-item label="结算单位:">
					<el-select v-model="value8" filterable placeholder="请选择" @change="getJsdw">
						<el-option v-for="item in wtdws" :key="item.dname" :label="item.dname" :value="item.dname">
						</el-option>
					</el-select>
					<!-- <el-input
              placeholder="请输入结算单位"
              maxlength="50"
              v-model="updateCustDataForm.jsdw"
            /> -->
				</el-form-item>
				<el-form-item label="开始地点:">
					<el-input placeholder="请输入开始地点" maxlength="50" v-model="updateCustDataForm.placestart" />
				</el-form-item>

				<el-form-item label="终止地点:">
					<el-input placeholder="请输入终止地点" maxlength="50" v-model="addCustDataForm.placeend" />
				</el-form-item>

				<el-form-item label="吨位:">
					<el-input placeholder="请输入吨位" maxlength="50" v-model="updateCustDataForm.dw" />
				</el-form-item>

				<el-form-item label="单价:">
					<el-input placeholder="请输入单价" maxlength="50" v-model="updateCustDataForm.dj" />
				</el-form-item>
				<el-form-item label="运费金额:">
					<el-input disabled="true" placeholder="请输入运费金额" maxlength="50" v-model="updateCustDataForm.zj" />
				</el-form-item>

				<el-form-item label="价格备注:">
					<el-input placeholder="请输入价格备注" maxlength="50" v-model="updateCustDataForm.jgbz" />
				</el-form-item>

				<el-form-item label="价格是否含税:">
					<input type="radio" name="radios5" value="1" v-model="updateCustDataForm.jghs"><label>是</label>
					<input type="radio" name="radios5" value="0" v-model="updateCustDataForm.jghs"><label>否</label>

				</el-form-item>

				<el-form-item label="运单发票号:">
					<el-input placeholder="请输入运单发票号" maxlength="50" v-model="updateCustDataForm.bzfqhm" />
				</el-form-item>
				<el-form-item label="代垫费:">
					<el-input placeholder="请输入代垫费" maxlength="50" v-model="updateCustDataForm.ddf" />
				</el-form-item>
				<el-form-item label="代垫费发票号:">
					<el-input placeholder="请输入代垫费发票号" maxlength="50" v-model="updateCustDataForm.ddffp" />
				</el-form-item>
				<el-form-item label="代垫费备注:">
					<el-input placeholder="请输入代垫费备注" maxlength="50" v-model="updateCustDataForm.ddfbz" />
				</el-form-item>

				<el-form-item label="代垫费是否支付:">
					<input type="radio" name="radios6" value="1" v-model="updateCustDataForm.isddf"><label>是</label>
					<input type="radio" name="radios6" value="0" v-model="updateCustDataForm.isddf"><label>否</label>

				</el-form-item>

				<el-form-item label="回单回收:">
					<input type="radio" name="radios7" value="1" v-model="updateCustDataForm.hdhs"><label>是</label>
					<input type="radio" name="radios7" value="0" v-model="updateCustDataForm.hdhs"><label>否</label>

				</el-form-item>
				<el-form-item label="提成:">
					<el-input placeholder="请输入提成" maxlength="50" v-model="updateCustDataForm.tc" />
				</el-form-item>

				<el-form-item label="总提成:">
					<el-input disabled="true" placeholder="请输入总提成" maxlength="50" v-model="updateCustDataForm.ztc" />
				</el-form-item>
				<el-form-item label="运单金额是否支付:">
					<input type="radio" name="radios8" value="1" v-model="updateCustDataForm.isydje"><label>是</label>
					<input type="radio" name="radios8" value="0" v-model="updateCustDataForm.isydje"><label>否</label>
					<!-- <el-input
              placeholder="请输入运单金额是否支付"
              maxlength="50"
              v-model="updateCustDataForm.isydje"
            /> -->
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="updateCustomer">确 定</el-button>
			</span>
		</el-dialog>
		<!-- <el-pagination v-if="allCustomer" background layout="sizes,prev, pager, next,jumper,->,total"
			:page-size.sync="pageSize" :current-page.sync="pageNum" :page-sizes="[15, 20, 30]" :total="total"
			:hide-on-single-page="true" @current-change="handleCurrentChange" @size-change="handleSizeChange">
		</el-pagination>
		<el-pagination v-if="selectCustomer" background layout="sizes,prev, pager, next,jumper,->,total"
			:page-size.sync="pageSize" :current-page.sync="pageNum" :page-sizes="[15, 20, 30]" :total="total"
			:hide-on-single-page="true" @current-change="searchCust" @size-change="searchCust">
		</el-pagination> -->
	</div>
</template>

<script>
import { watch } from "fs";
import moment from "moment"
export default {
	data() {
		return {
			height: '${document.documentElement.clientHeight}',//浏览器高度
			// 存储后端返回给前端的客户列表
			custList: [],
			// “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
			addBox: false,
			cars: [],
			emms: [],
			allCustomer: true,
			// “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
			updateBox: false,
			selectCustomer: false,
			value1: '',
			value2: '',
			value3: '',
			value4: '',
			value5: '',
			value6: '',
			value7: '',
			value8: '',
			value13: '',
			value14: '',
			eid: '',
			cid: '',
			wtdw: '',
			jsdw: '',
			wtdws: [],
			jsdws: [],
			sum: '',
			// “添加客户”对话框的表单
			addCustDataForm: {
				yid: '',
				eid: '',
				cid: '',
				ytime: '',
				wtdw: '',
				jsdw: '',
				dw: '',
				placestart: '',
				placeend: '',
				dj: '',
				dcje: '',
				jgbz: '',
				jghs: '',
				bzfqhm: '',
				ddf: '',
				ddfbz: '',
				isddf: '',
				hdhs: '',
				tc: '',
				ztc: '',
				isydje: '',
				zj: '',
				ddffp: ''

			},
			// “修改客户”对话框的表单
			updateCustDataForm: {
				yid: '',
				ename: '',
				cid: '',
				ytime: '',
				wtdw: '',
				jsdw: '',
				dw: '',
				placestart: '',
				placeend: '',
				dj: '',
				dcje: '',
				jgbz: '',
				jghs: '',
				bzfqhm: '',
				ddf: '',
				ddfbz: '',
				isddf: '',
				hdhs: '',
				tc: '',
				ztc: '',
				isydje: '',
				zj: '',
				ddffp: ''
			},
			// 搜索表单
			searchCustForm: {
				yid: '',
				
				eid: '',
				cid: '',
				ytime: '',
				wtdw: '',
				jsdw: '',
				dw: '',
				placestart: '',
				placeend: '',
				dj: '',
				dcje: '',
				jgbz: '',
				jghs: '',
				bzfqhm: '',
				ddf: '',
				ddfbz: '',
				isddf: '',
				hdhs: '',
				tc: '',
				ztc: '',
				isydje: '',
				zj: '',
				ddffp: ''
			},
			total: 0,
			pageNum: 1,
			pageSize: 15,
		}
	},
	methods: {


		handleSizeChange: function (size) {
			this.pageSize = size;
			console.log(this.pageSize)  //每页下拉显示数据
		},
		handleCurrentChange: function (currentPage) {
			this.pageNum = currentPage;
			console.log(this.pageNum)  //点击第几页
		},
		getWtdw(value) {
			this.wtdw = value;
		},
		getJsdw(value) {
			this.jsdw = value;
		},
		getEid(value) {
			this.eid = value;
		},
		getCid(value) {
			this.cid = value;
		},
		rounding(row, column) {
			return parseFloat(row[column.property]).toFixed(2)
		},
		dateFormat(row, column) {
			let date = row[column.property];
			if (date === undefined) {
				return ''
			};
			return moment(date).format("yyyy-MM-DD")
		},
		//导出
		exportexcel(){
					 this.$alert(
					         <div class="el-message-box--custom-body">
					           <div style="font-size:16px">确认导出所有数据？</div>
					         </div>,
					         "数据导出",
					         {
					           showCancelButton: true,				          
					           customClass: "el-message-box--noicon el-message-box--custom",
					           confirmButtonText: "导出"
					         }
					       ).then(() => {
					         window.open('https://jingjie-back.goomo.top/yd/export', "_blank");
							 // window.open('/car/export', "_blank");
					       });			 	 
		  
		},
		searchCust(){
			console.log("11"); 	
			this.$axios.post('/yd/search',		 	
					{				
					yid: this.searchCustForm.yid,
					eid: this.eid,
					cid: this.cid,
					ytime: this.searchCustForm.ytime,
					wtdw: this.searchCustForm.wtdw,
					jsdw: this.searchCustForm.jsdw,
					dw: this.searchCustForm.dw,
					placestart: this.searchCustForm.placestart,
					placeend: this.searchCustForm.placeend,
					dj: this.searchCustForm.dj,
					dcje: this.searchCustForm.dcje,
					jgbz: this.searchCustForm.jgbz,
					jghs: this.searchCustForm.jghs,
					bzfqhm: this.searchCustForm.bzfqhm,
					ddf: this.searchCustForm.ddf,
					ddfbz: this.searchCustForm.ddfbz,
					isddf: this.searchCustForm.isddf,
					hdhs: this.searchCustForm.hdhs,
					tc: this.searchCustForm.tc,
					ztc: this.searchCustForm.ztc,
					isydje: this.searchCustForm.isydje,
					zj: this.searchCustForm.zj,
					ddffp: this.searchCustForm.ddffp
						
					})
			.then((resp) =>{
			if(resp.data.data.empList != null){
				console.log("124");
					this.custList = resp.data.data.empList;
					if(this.custList[0] != null){
						console.log(242)
						this.sum = this.custList[0].sum;						
					}					
					// this.total = resp.data.data.empList.total;
					// this.pageNum = resp.data.data.empList.pageNum;
					// this.allCustomer = false;
					// this.selectCustomer = true;
					// this.eid='';
					// this.cid='';
				}else
				{
					this.$message.error("没有相关用户信息");
				}
			}).catch((error) =>{
				console.log(error);
				this.$message.error("请求发送失败");
			})	 		
			},
		
		// 修改之前的数据回显
		preUpdateCust(yid) {
			this.$axios.get('/yd/getyd/' + yid)
				.then((resp) => {
					// 条件成立：表示能够从后端获取对应的客户信息，完成数据回显
					if (resp.data.message == 'success') {
						// 显示“修改客户”对话框
						this.updateBox = true;
						// 将后端的客户信息赋值给前端定义的修改表单
						this.updateCustDataForm = resp.data.data.ydById;
						this.value1 = this.updateCustDataForm.ename;
						this.value2 = this.updateCustDataForm.carone;
						this.value7 = this.updateCustDataForm.wtdw;
						this.value8 = this.updateCustDataForm.jsdw;
						console.log(this.value1);
					} else {
						this.$message.error("没有找到对应的客户信息");
					}
				})
				.catch((error) => {
					this.$message.error("修改客户信息数据回显请求发送失败");
					console.log(error);
				})
			this.$axios.get('/emm/allemm').then((resp) => {
				this.emms = resp.data.data.emmlists;
			}).catch((error) => {
				this.$message.error("请求失败");
				console.log(error);
			})
			this.$axios.get('/car/allcar').then((resp) => {
				this.cars = resp.data.data.carLists;
				console.log(this.cars);
			}).catch((error) => {
				this.$message.error("请求失败");
				console.log(error);
			})
			this.$axios.get('/danwei/all').then((resp) => {
				this.wtdws = resp.data.data.dwlists;

				console.log(this.wtdws);
			}).catch((error) => {
				this.$message.error("请求失败");
				console.log(error);
			})

		},
		// 修改客户信息
		updateCustomer() {
			this.$axios.post('/yd/update', {
				yid: this.updateCustDataForm.yid,
				eid: this.eid,
				cid: this.cid,
				ytime: this.updateCustDataForm.ytime,
				wtdw: this.wtdw,
				jsdw: this.jsdw,
				dw: this.updateCustDataForm.dw,
				placestart: this.updateCustDataForm.placestart,
				placeend: this.updateCustDataForm.placeend,
				dj: this.updateCustDataForm.dj,
				dcje: this.updateCustDataForm.dcje,
				jgbz: this.updateCustDataForm.jgbz,
				jghs: this.updateCustDataForm.jghs,
				bzfqhm: this.updateCustDataForm.bzfqhm,
				ddf: this.updateCustDataForm.ddf,
				ddfbz: this.updateCustDataForm.ddfbz,
				isddf: this.updateCustDataForm.isddf,
				hdhs: this.updateCustDataForm.hdhs,
				tc: this.updateCustDataForm.tc,
				ztc: this.updateCustDataForm.ztc,
				isydje: this.updateCustDataForm.isydje,
				zj: this.updateCustDataForm.zj,
				ddffp: this.updateCustDataForm.ddffp

			}).then((resp) => {
				// 条件成立：修改成功，否则修改失败
				if (resp.data.code == 20000) {
					this.$message.success("修改成功");
				} else {
					this.$methods.error("修改失败");
				}
				// 隐藏对话框
				this.updateBox = false;
				// 重新查询修改之后的客户信息
				this.customerList();
				this.value1 = '';
				this.value2 = '';
				this.value7 = '';
				this.value8 = '';

			}).catch((error) => {
				this.$message.error("修改客户信息请求发送失败");
				console.log(error);
			})

		},
		// 根据客户ID删除客户信息
		deleteCust(yid) {
			this.$confirm("确定要删除吗", "提示", {
				confirmButtonText: '确定',
				cannelButtonText: '取消',
				type: '警告'
			})
				// 点击确定执行删除操作
				.then(() => {
					// 删除操作之前先做check(验证当前客户有没有管理送水历史，如果有提示用户该客户有
					// 对应的送水历史关联)
					this.$axios.post('/yd/delete/' + yid)
						// then方法的resp参数封装了后端返回给前端的数据
						.then((resp) => {
							// 条件成立：表示该客户有对应的送水历史关联，阻止删除
							if (resp.data.code == 20000) {
								this.$message.success("删除成功");
							} else {
								this.$message.error("删除失败");
							}
							this.customerList();
						}).catch((error) => {
							this.$message.error("删除客户信息请求发送失败");
							console.log(error);
						})

				})
		},
		// 预添加客户信息，弹出“添加客户”对话框
		preSaveCustomer() {
			this.$axios.get('/emm/allemm').then((resp) => {

				this.emms = resp.data.data.emmlists;

			}).catch((error) => {

				this.$message.error("请求失败");

				console.log(error);

			})
			this.$axios.get('/car/allcar').then((resp) => {
				this.cars = resp.data.data.carLists;
				console.log(this.cars);
			}).catch((error) => {
				this.$message.error("请求失败");
				console.log(error);
			})
			this.$axios.get('/danwei/all').then((resp) => {
				this.wtdws = resp.data.data.dwlists;

				console.log(this.wtdws);
			}).catch((error) => {
				this.$message.error("请求失败");
				console.log(error);
			})
			this.addBox = true;

		},
		// 关闭对话框之前做的动作
		handleClose(done) {
			// 清空上一次表单数据
			this.addCustDataForm.yid = '';
			this.addCustDataForm.eid = '';
			this.addCustDataForm.cid = '';
			this.addCustDataForm.ytime = '';
			this.addCustDataForm.wtdw = '';
			this.addCustDataForm.jsdw = '';
			this.addCustDataForm.dw = '';
			this.addCustDataForm.placestart = '';
			this.addCustDataForm.placeend = '';
			this.addCustDataForm.dj = '';
			this.addCustDataForm.dcje = '';
			this.addCustDataForm.jgbz = '';
			this.addCustDataForm.jghs = '';
			this.addCustDataForm.bzfqhm = '';
			this.addCustDataForm.ddf = '';
			this.addCustDataForm.ddfbz = '';
			this.addCustDataForm.isddf = '';
			this.addCustDataForm.hdhs = '';
			this.addCustDataForm.address = '';
			this.addCustDataForm.tc = '';
			this.addCustDataForm.ztc = '';
			this.addCustDataForm.isydje = '';
			this.updateCustDataForm.ddffp = '';
			this.value1 = '';
			this.value2 = '';
			this.value3 = '';
			this.value4 = '';
			this.value5 = '';
			this.value6 = '';
			this.value7 = '';
			this.value8 = '';
			this.eid = '';
			this.cid = '';
			// 关闭对话框
			done();
		},
		// 提交对话框添加客户信息
		saveCustomer() {
			this.$axios.post('/yd/insert', {
				// 前端使用JSON格式将表单参数传入给后端

				eid: this.eid,
				cid: this.cid,
				ytime: this.addCustDataForm.ytime,
				wtdw: this.wtdw,
				jsdw: this.jsdw,
				dw: this.addCustDataForm.dw,
				placestart: this.addCustDataForm.placestart,
				placeend: this.addCustDataForm.placeend,
				dj: this.addCustDataForm.dj,
				dcje: this.addCustDataForm.dcje,
				jgbz: this.addCustDataForm.jgbz,
				jghs: this.addCustDataForm.jghs,
				bzfqhm: this.addCustDataForm.bzfqhm,
				ddf: this.addCustDataForm.ddf,
				ddfbz: this.addCustDataForm.ddfbz,
				isddf: this.addCustDataForm.isddf,
				hdhs: this.addCustDataForm.hdhs,
				tc: this.addCustDataForm.tc,
				ztc: this.addCustDataForm.ztc,
				isydje: this.addCustDataForm.isydje,
				zj: this.updateCustDataForm.zj,
				ddffp: this.updateCustDataForm.ddffp
			}).then((resp) => {
				// 接受后端返回给前端你的消息
				// 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
				if (resp.data.code == 20000) {
					this.$message.success("添加成功");
				} else {
					this.$message.error("添加失败");
				}
				// 重新查询客户信息
				this.customerList();
				// 关闭对话框
				this.addBox = false;
			}).catch((error) => {
				this.$message.error("添加客户请求发送失败");
				console.log(error);
			})

		},
		// 向后端发起异步请求，查询所有的客户信息
		customerList() {
			this.$axios.get('/emm/allemm').then((resp) => {
				this.emms = resp.data.data.emmlists;
			}).catch((error) => {
				this.$message.error("请求失败");
				console.log(error);
			})
			this.$axios.get('/car/allcar').then((resp) => {
				this.cars = resp.data.data.carLists;
				console.log(this.cars);
			}).catch((error) => {
				this.$message.error("请求失败");
				console.log(error);
			})
			// this.$axios.get('/yd/allyd/' + this.pageNum + "/" + 10000000)
			this.$axios.get('/yd/allyd')
				.then((resp) => {
					// 获取后端返回给前端的信息
					if (resp.data.message == 'success') {
						// this.$message.error("向后端发起客户列表请求成功");
						// 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
						this.custList = resp.data.data.ydLists;
						this.total = this.custList.length;
						// this.pageNum = 1;
						this.allCustomer = true;
						this.selectCustomer = false;
						console.log(this.total);
					}
				}).catch((error) => {
					this.$message.error("向后端发起客户列表请求失败");
					console.log(error);
				})
		}
	},
	// created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
	created() {
		this.customerList()
	},
}
</script>
