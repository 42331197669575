<template>
    <div id="car">
                
               <el-form :inline="true" :model="searchCustForm" class="demo-form-inline">
                  <el-button type="primary" @click="exportexcel">导出</el-button>
                  &nbsp;&nbsp;&nbsp;
				  <el-button type="primary" @click="preSaveCustomer">添加</el-button>
                  &nbsp;&nbsp;&nbsp;
                  <el-form-item label="员工姓名">
                    <el-select v-model="value12" placeholder="请选择" @change="getEid">
						<el-option label="全选" value="">
						</el-option>
						  <el-option
							  v-for="item in emms"
							  :key="item.eid"
							  :label="item.ename"
							  :value="item.eid">
						  </el-option>
                      </el-select>	
                  </el-form-item>

				<el-form-item label="支出名称:">
		            <el-input
		              placeholder="请输入支出名称"
		              maxlength="50"
		              v-model="searchCustForm.petype"
		            />
		          </el-form-item>		          
		
		          <el-form-item label="备注:">
		            <el-input
		              placeholder="请输入备注"
		              maxlength="50"
		              v-model="searchCustForm.bz"
		            />
		          </el-form-item>
				  
				  <el-form-item label="时间:">
					  <el-date-picker
					   placeholder="请选择时间"   
					  	type="date"
					   v-model="searchCustForm.ptime">
					  </el-date-picker>
				    
				  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="searchCust">搜索</el-button>
                  </el-form-item>
                </el-form>
                <el-table
                    :data="carLists"
                    stripe
                    style="width: 100%">
					
					<!-- <el-table-column
					prop="peid"
					label="编号"
					width="150">
					</el-table-column> -->
					<el-table-column
					label="编号"
						 type="index"
						 width="150">
					   </el-table-column>

                   <el-table-column
                    prop="ename"
                    label="员工姓名"
                    width="150">
                    </el-table-column>

                    <el-table-column
                    prop="petype"
                    label="支出名称"
                    width="150">
                    </el-table-column>

                    <el-table-column
                    prop="peprice"					
                    label="金额"
					width="150">		
                    </el-table-column>

                    <el-table-column
                    prop="bz"
                    label="备注"
                    width="200">
                    </el-table-column>
					
					<el-table-column
					prop="ptime"
					:formatter="dateFormat"
					label="时间">
					</el-table-column>


			

                    <el-table-column
                    label="操作" width="200px">
                    <template slot-scope="scope">
                      
                        <el-button type="primary" 
                        icon="el-icon-delete" 
                        @click="deleteCust(scope.row.peid)">
                        </el-button>
                      
                        <el-button type="danger" 
                        icon="el-icon-edit" 
                        @click="preUpdateCust(scope.row.peid)">
                        </el-button>
                    </template>
                </el-table-column>
                </el-table>
		<el-dialog
		        title="添加记录"
		        :visible.sync="addBox"
		        width="50%"
		        :before-close="handleClose"
		       >
		        <el-form
		          ref="addCustDataForm"
		          label-width="100px"
		          v-model="addCustDataForm"
		        >
		          <el-form-item label="员工姓名:">
					  <el-select v-model="value2" placeholder="请选择" @change="getEid">
					    <el-option
					        v-for="item in emms"
					        :key="item.eid"
					        :label="item.ename"
					        :value="item.eid">
					    </el-option>
					    </el-select>	 
		            
		          </el-form-item>
		
		          <el-form-item label="支出名称:">
		            <el-input
		              placeholder="请输入支出名称"
		              maxlength="50"
		              v-model="addCustDataForm.petype"
		            />
		          </el-form-item>
		          <el-form-item label="金额:">
		            <el-input
		              placeholder="请输入金额"
		              maxlength="50"
		              v-model="addCustDataForm.peprice"
		            />
		          </el-form-item>
		
		          <el-form-item label="备注:">
		            <el-input
		              placeholder="请输入备注"
		              maxlength="50"
		              v-model="addCustDataForm.bz"
		            />
		          </el-form-item>
				  
				  <el-form-item label="时间:">
					  <el-date-picker
					   placeholder="请选择时间"   
					  	type="date"
					   v-model="addCustDataForm.ptime">
					  </el-date-picker>
				    <!-- <el-input
				      placeholder="请输入时间"
				      maxlength="50"
				      v-model="addCustDataForm.ptime"
				    /> -->
				  </el-form-item>
				
		
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="saveCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		<!-- 
		  修改客户对话框 
		  updateBox表示修改对话框显示和隐藏的标志：true显示，false隐藏
		  updateHandleClose表示关闭窗体之前发生的动作
		-->
		      <el-dialog
		        title="修改记录"
		        :visible.sync="updateBox"
		        width="50%"
		        :before-close="updateHandleClose"
		       >
		        <el-form
		          ref="updateCustDataForm"
		          label-width="100px"
		          v-model="updateCarDataForm"
		        >
		          <el-form-item label="员工姓名:">
					  <el-select v-model="value1" placeholder="请选择" @change="getEid">
					    <el-option
					        v-for="item in emms"
					        :key="item.eid"
					        :label="item.ename"
					        :value="item.eid">
					    </el-option>
					    </el-select>	 	
		            <!-- <el-input
		              placeholder="请输入员工姓名"
		              maxlength="50"
		              v-model="updateCarDataForm.eid"
		            /> -->
		          </el-form-item>
		          		
		          <el-form-item label="支出名称:">
		            <el-input
		              placeholder="请输入支出名称"
		              maxlength="50"
		              v-model="updateCarDataForm.petype"
		            />
		          </el-form-item>
		          <el-form-item label="金额:">
		            <el-input
		              placeholder="请输入金额"
		              maxlength="50"
		              v-model="updateCarDataForm.peprice"
		            />
		          </el-form-item>
		          		
		          <el-form-item label="备注:">
		            <el-input
		              placeholder="请输入备注"
		              maxlength="50"
		              v-model="updateCarDataForm.bz"
		            />
		          </el-form-item>
		          
		          <el-form-item label="时间:">
					  <el-date-picker
					   placeholder="请选择时间"   
					  	type="date"
					   v-model="updateCarDataForm.ptime">
					  </el-date-picker>
		          
		          </el-form-item>
				  
				 
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="updateCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		      <el-pagination v-if="allCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="customerList"
						    @size-change="customerList" >
						</el-pagination>
						<el-pagination v-if="selectCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="searchCust"
						    @size-change="searchCust" >
						</el-pagination>
		    </div>
</template>

<script>
import moment from "moment"
  export default {
	  
	 
    data() {
      return {
        // 存储后端返回给前端的客户列表
        carLists: [],
        // “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
        addBox:false,
        allCustomer:true,
        // “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
        updateBox:false,
        selectCustomer:false,
		emms: [],
        // “添加客户”对话框的表单
        addCustDataForm:{
           peid:'',
           eid:'',
           petype:'',
           peprice:'',
           bz:'',
           ptime:''
        },
		eid:'',
		value1:'',
		value2:'',
		value12:'',
        // “修改客户”对话框的表单
        updateCarDataForm:{
           peid:'',
           eid:'',
           petype:'',
           peprice:'',
           bz:'',
           ptime:''
        },
        // 搜索表单
        searchCustForm:{
            eid:'',
            petype:'',
            peprice:'',
            bz:'',
            ptime:''
        },
        total : 0,
				pageNum : 1,
				pageSize : 10,
      }
    },
    methods: {
		getEid(value){
		  this.eid = value;
		},
         dateFormat(row,column){
           let date = row[column.property];
           if(date === undefined){
             return ''
           } ;
           return moment(date).format("yyyy-MM-DD")
         },
		 //导出
		 exportexcel(){
		 			 this.$alert(
		 			         <div class="el-message-box--custom-body">
		 			           <div style="font-size:16px">确认导出所有数据？</div>
		 			         </div>,
		 			         "数据导出",
		 			         {
		 			           showCancelButton: true,				          
		 			           customClass: "el-message-box--noicon el-message-box--custom",
		 			           confirmButtonText: "导出"
		 			         }
		 			       ).then(() => {
		 			         window.open('https://jingjie-back.goomo.top/pay/exportemp', "_blank");
		 					 // window.open('/car/export', "_blank");
		 			       });			 	 
		   
		 },
		 searchCust(){
			
		 	console.log("11"); 	
		 	this.$axios.post('/pay/search2/'+this.pageNum+'/'+this.pageSize,		 	
		 			{				
		 				
		 				eid: this.eid,
		 				petype: this.searchCustForm.petype,
		 				peprice: this.searchCustForm.peprice,
		 				bz: this.searchCustForm.bz,
		 				ptime: this.searchCustForm.ptime
		 			})
		 	.then((resp) =>{
		 	if(resp.data.data.empList2 != null){
		 			this.carLists = resp.data.data.empList2.list;
		 			this.total = resp.data.data.empList2.total;
		 			this.pageNum = resp.data.data.empList2.pageNum;
		 			this.allCustomer = false;
		 			this.selectCustomer = true;
					// this.value12= '';
					// this.eid = '';
		 		}else
		 		{
		 			this.$message.error("没有相关用户信息");
		 		}
		 	}).catch((error) =>{
		 		console.log(error);
		 		this.$message.error("请求发送失败");
		 	})	 		
		 	},
		// 修改之前的数据回显
		preUpdateCust(peid) {
		   this.$axios.get('/pay/getpe/'+peid)
		   .then((resp)=>{
		      // 条件成立：表示能够从后端获取对应的客户信息，完成数据回显
		      if (resp.data.message == 'success') {
		        // 显示“修改客户”对话框
		        this.updateBox = true;
		        // 将后端的客户信息赋值给前端定义的修改表单
		        this.updateCarDataForm = resp.data.data.peById;
				// this.value1 = this.updateCustDataForm.ename;
		      } else {
		        this.$message.error("没有找到对应的客户信息");
		      }
		   })
		   .catch((error)=>{
		      this.$message.error("修改客户信息数据回显请求发送失败");
		      console.log(error);
		   })
		   this.$axios.get('/emm/allemm').then((resp)=>{
		     this.emms = resp.data.data.emmlists;
		   }).catch((error)=>{
		     this.$message.error("请求失败");
		     console.log(error);
		   })
		},
		// 修改客户信息
		updateCustomer(){
		    this.$axios.post('/pay/updatepe',{
		      peid: this.updateCarDataForm.peid,
		      eid: this.eid,
		      petype: this.updateCarDataForm.petype,
		      peprice: this.updateCarDataForm.peprice,
		      bz: this.updateCarDataForm.bz,
			  ptime: this.updateCarDataForm.ptime
			 
		    }).then((resp)=>{
		      // 条件成立：修改成功，否则修改失败
		      if (resp.data.code == 20000) {
		         this.$message.success("修改成功");
		      } else {
		         this.$message.error("修改失败");
		      }
		      // 隐藏对话框
		      this.updateBox = false;
		      // 重新查询修改之后的客户信息
		      this.customerList();
		    }).catch((error)=>{
		       this.$message.error("修改客户信息请求发送失败");
		       console.log(error);
		    })
		    
		},
		// 根据客户ID删除客户信息
		deleteCust(peid){
		    this.$confirm("确定要删除吗","提示",{
		        confirmButtonText:'确定',
		        cannelButtonText:'取消',
		        type:'警告'
		    })
		    // 点击确定执行删除操作
		    .then(()=>{
		          // 删除操作之前先做check(验证当前客户有没有管理送水历史，如果有提示用户该客户有
		          // 对应的送水历史关联)
		          this.$axios.post('/pay/deletepe/'+peid)
		          // then方法的resp参数封装了后端返回给前端的数据
		                    .then((resp)=>{
		                        // 条件成立：表示该客户有对应的送水历史关联，阻止删除
		                       if(resp.data.code == 20000) {
		                               this.$message.success("删除成功");
		                           } else {
		                               this.$message.error("删除失败");
		                           }
		                           this.customerList();
		                    }).catch((error)=>{
                                    this.$message.error("删除客户信息请求发送失败");
                                    console.log(error);
                                })
							
		          })
		},
		// 预添加客户信息，弹出“添加客户”对话框
		preSaveCustomer(){
			this.$axios.get('/emm/allemm').then((resp)=>{
			  this.emms = resp.data.data.emmlists;
			}).catch((error)=>{
			  this.$message.error("请求失败");
			  console.log(error);
			})
		    this.addBox = true;
		
		},
		// 关闭对话框之前做的动作
		handleClose(done){
		    // 清空上一次表单数据
		    this.addCustDataForm.eid='';
		    this.addCustDataForm.petype='';
		    this.addCustDataForm.peprice='';
			this.addCustDataForm.ptime='';
			this.addCustDataForm.bz='';
			this.value1='';
			this.value2='';
			this.eid='';
		    // 关闭对话框
		    done();
		},
		// 提交对话框添加客户信息
		saveCustomer(){
		    this.$axios.post('/pay/insertpe',{
		        // 前端使用JSON格式将表单参数传入给后端
		       
		      eid: this.eid,
		      petype: this.addCustDataForm.petype,
		      peprice: this.addCustDataForm.peprice,
		      bz: this.addCustDataForm.bz,
		      ptime: this.addCustDataForm.ptime
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error("添加失败");
		        }
		        // 重新查询客户信息
		        this.customerList();
		        // 关闭对话框
		        this.addBox = false;
				this.value2 = '';
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
        // 向后端发起异步请求，查询所有的客户信息
      customerList(){
		  this.$axios.get('/emm/allemm').then((resp)=>{
		    this.emms = resp.data.data.emmlists;
		  }).catch((error)=>{
		    this.$message.error("请求失败");
		    console.log(error);
		  })
					this.$axios.get('/pay/allpayem/'+this.pageNum+"/"+this.pageSize)
					        .then((resp)=>{
					            // 获取后端返回给前端的信息
					            if (resp.data.message == 'success') {
									 // this.$message.error("向后端发起客户列表请求成功");
					                // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
					                this.carLists = resp.data.data.pemplist.list;
									this.total = resp.data.data.pemplist.total;
									this.pageNum = resp.data.data.pemplist.pageNum;
									this.allCustomer = true;
									this.selectCustomer = false;
					            }
					        }).catch((error)=>{
					            // this.$message.error("向后端发起客户列表请求失败");
					            console.log(error);
					        })
					    }
					},
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.customerList()
    },
  }
</script>
