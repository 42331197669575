<template>
    <div id='count'>
            <el-form :inline="true"  class="demo-form-inline">
             <!-- <el-form-item>
                <el-date-picker
                    v-model="startTime"
                    type="date"
                    placeholder="选择开始日期">
                </el-date-picker>
                <el-date-picker
                    v-model="endTime"
                    type="date"
                    placeholder="选择结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="searchCust">查询</el-button>
              </el-form-item> -->
            </el-form>						
			<el-col :span="10"  style="font-size:30px">
			    <el-card shadow="always">
			      金额汇总
			    </el-card>
			  </el-col>
			<el-col :span="10"  style="font-size:30px">
			    <el-card shadow="always">
			     {{total.toFixed(2)}}
			    </el-card>
			  </el-col>
              
               
              
    </div>
</template>


<script>
import moment from "moment"
  export default {
    data() {
      return {
        countList:[],
		total:'',
		startTime:'',
		endTime:'',
      }
    },
    methods: {
		searchCust(){
			console.log("11"); 	
			this.startTime = moment(this.startTime).format("YYYY-MM-DD");
			this.endTime = moment(this.endTime).format("YYYY-MM-DD");
			this.$axios.post('/money/search2/'+this.startTime+'/'+this.endTime)
			.then((resp) =>{
			if(resp.data.message == 'success'){
				 this.total = resp.data.data.total;
					// this.carLists = resp.data.data.empList.list;
					// this.total = resp.data.data.empList.total;
					// this.pageNum = resp.data.data.empList.pageNum;
					// this.allCustomer = false;
					// this.selectCustomer = true;
				}else
				{
					this.$message.error("向后端发起客户列表请求失败1");
				}
			}).catch((error) =>{
				console.log(error);
				this.$message.error("向后端发起客户列表请求失败2");
			})	 		
			},
      listCount(){
            this.$axios.get('/money/hz')
            .then((resp)=>{
                // 获取后端返回给前端的信息
                if (resp.data.message == 'success') {
                    // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
                    this.total = resp.data.data.total;
					
                }
            }).catch((error)=>{
                this.$message.error("向后端发起客户列表请求失败");
                console.log(error);
            })
        }
    },
	
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.listCount()
    },
    }
  
</script>
