<template>
    <div id="car">
                
               <el-form :inline="true" :model="searchCustForm" class="demo-form-inline">
				   <el-button type="primary" @click="exportexcel">导出</el-button>
				   &nbsp;&nbsp;&nbsp;
                  <el-button type="primary" @click="preSaveCustomer">添加</el-button>
                  &nbsp;&nbsp;&nbsp;
                  <el-form-item label="车牌号:">
                  	  <el-select v-model="value6" placeholder="请选择" @change="getCid">
						  <el-option label="全选" value="">
						  </el-option>
                  	    <el-option
                  	        v-for="item in cars"
                  	        :key="item.cid"
                  	        :label="item.carone"
                  	        :value="item.cid">
                  	    </el-option>
                  	    </el-select>
                    </el-form-item>
                  		
                    <el-form-item label="支出名称:">
                  	  <input type="radio" name="radios11" value="轮胎" v-model="searchCustForm.pname"><label>轮胎</label>
                  	  <input type="radio" name="radios11" value="过路费" v-model="searchCustForm.pname"><label>过路费</label>
                  	  <input type="radio" name="radios11" value="车辆维修" v-model="searchCustForm.pname"><label>车辆维修</label>
                  	  <input type="radio" name="radios11" value="保养费" v-model="searchCustForm.pname"><label>保养费</label>
                  	  <input type="radio" name="radios11" value="油费" v-model="searchCustForm.pname"><label>油费</label>
                  	  <input type="radio" name="radios11" value="尿素" v-model="searchCustForm.pname"><label>尿素</label>
                  	  <input type="radio" name="radios11" value="其他" v-model="searchCustForm.pname"><label>其他</label> 
					  <input type="radio" name="radios11" value="" v-model="searchCustForm.pname"><label>全选</label> 
                    </el-form-item>             
                  
                  <el-form-item label="备注:">
                    <el-input
                      placeholder="请输入备注"
                      maxlength="50"
                      v-model="searchCustForm.bz"
                    />
                  </el-form-item>
                  
                  <el-form-item label="支付油卡:">
                  	<el-input
                  	  placeholder="请输入支付油卡"
                  	  maxlength="50"
                  	  v-model="searchCustForm.oidcard"
                  	/>
                    </el-form-item>
                  		
                    <el-form-item label="是否已支付:">
                  	  <input type="radio" name="radios12" value="1" v-model="searchCustForm.ispay"><label>是</label>
                  	  <input type="radio" name="radios12" value="0" v-model="searchCustForm.ispay"><label>否</label>
					  <input type="radio" name="radios12" value="" v-model="searchCustForm.ispay"><label>全选</label>
                    </el-form-item>
                    
                   <el-form-item label="记录时间:">
                  	  <el-date-picker
                  	   placeholder="请选择时间"   
                  	  	type="date"
                  	   v-model="searchCustForm.ptime">
                  	    </el-date-picker>
                    </el-form-item>
                  

                  <el-form-item>
                    <el-button type="primary" @click="searchCust">搜索</el-button>
                  </el-form-item>
                </el-form>
                <el-table
                    :data="carLists"
                    stripe
                    style="width: 100%">
					
					<!-- <el-table-column
					prop="pcarid"
					label="编号"
					width="150">
					</el-table-column> -->
					<el-table-column
					label="编号"
						 type="index"
						 width="150">
					   </el-table-column>

                   <el-table-column
                    prop="carone"
                    label="车牌号"
                    width="150">
                    </el-table-column>
					
                    <el-table-column
                    prop="pname"
                    label="名称"
                    width="150">
                    </el-table-column>

                    <el-table-column
                    prop="price"	
                    label="支出价格"
					width="150">
                    </el-table-column>
					
					<el-table-column
					prop="bz"	
					label="备注"
					width="150">
					</el-table-column>
					
					<el-table-column
					 prop="oidcard"
					 label="支付油卡"
					 width="150">
					 </el-table-column>
					

                    <el-table-column
                    prop="ispay"
                    label="是否支付"
                    width="150">
					<template slot-scope="scope">{{ scope.row.ispay == 1 ? '是' : '否' }}</template>
                    </el-table-column>
					
					<el-table-column
					prop="ptime"
					:formatter="dateFormat"
					label="记录时间">
					</el-table-column>


			

                    <el-table-column
                    label="操作" width="200px">
                    <template slot-scope="scope">
                      
                        <el-button type="primary" 
                        icon="el-icon-delete" 
                        @click="deleteCust(scope.row.pcarid)">
                        </el-button>
                      
                        <el-button type="danger" 
                        icon="el-icon-edit" 
                        @click="preUpdateCust(scope.row.pcarid)">
                        </el-button>
                    </template>
                </el-table-column>
                </el-table>
		<el-dialog
		        title="添加记录"
		        :visible.sync="addBox"
		        width="50%"
		        :before-close="handleClose"
		       >
		        <el-form
		          ref="addCustDataForm"
		          label-width="100px"
		          v-model="addCustDataForm"
		        >
		          <el-form-item label="车牌号:">
					  <el-select v-model="value2" placeholder="请选择" @change="getCid">
					    <el-option
					        v-for="item in cars"
					        :key="item.cid"
					        :label="item.carone"
					        :value="item.cid">
					    </el-option>
					    </el-select>
		          </el-form-item>
		
		          <el-form-item label="项目类型:">
					  <input type="radio" name="radios1" value="0" v-model="addCustDataForm.pname"><label>轮胎</label>
					  <input type="radio" name="radios1" value="1" v-model="addCustDataForm.pname"><label>过路费</label>
					  <input type="radio" name="radios1" value="2" v-model="addCustDataForm.pname"><label>车辆维修</label>
					  <input type="radio" name="radios1" value="3" v-model="addCustDataForm.pname"><label>保养费</label>
					  <input type="radio" name="radios1" value="4" v-model="addCustDataForm.pname"><label>油费</label>
					  <input type="radio" name="radios1" value="5" v-model="addCustDataForm.pname"><label>尿素</label>
					  <input type="radio" name="radios1" value="6" v-model="addCustDataForm.pname"><label>其他</label> 
		          </el-form-item>
		          <el-form-item label="支出金额:">
		            <el-input
		              placeholder="请输入支出金额"
		              maxlength="50"
		              v-model="addCustDataForm.price"
		            />
		          </el-form-item>
				
				<el-form-item label="备注:">
				  <el-input
				    placeholder="请输入备注"
				    maxlength="50"
				    v-model="addCustDataForm.bz"
				  />
				</el-form-item>
				
				<el-form-item label="选择支付油卡:">
					<el-select v-model="value3" placeholder="请选择" @change="getOid">
					  <el-option
						  v-for="item in oids"
						  :key="item.oidcard"
						  :label="item.oidcard"
						  :value="item.oidcard">
					  </el-option>
					  </el-select>
				  </el-form-item>
		
		          <el-form-item label="是否已支付:">
					  <input type="radio" name="radios" value="1" v-model="addCustDataForm.ispay"><label>是</label>
					  <input type="radio" name="radios" value="0" v-model="addCustDataForm.ispay"><label>否</label>
		          </el-form-item>
				  
				 <el-form-item label="记录时间:">
					  <el-date-picker
					   placeholder="请选择时间"   
					  	type="date"
					   v-model="addCustDataForm.ptime">
					    </el-date-picker>
				  </el-form-item>
				
		
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="saveCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		<!-- 
		  修改客户对话框 
		  updateBox表示修改对话框显示和隐藏的标志：true显示，false隐藏
		  updateHandleClose表示关闭窗体之前发生的动作
		-->
		      <el-dialog
		        title="修改记录"
		        :visible.sync="updateBox"
		        width="50%"
		        :before-close="updateHandleClose"
		       >
		        <el-form
		          ref="updateCustDataForm"
		          label-width="100px"
		          v-model="updateCarDataForm"
		        >
		          <el-form-item label="车牌号:">
					  <el-select v-model="value1" placeholder="请选择" @change="getCid">
					    <el-option
					        v-for="item in cars"
					        :key="item.cid"
					        :label="item.carone"
					        :value="item.cid">
					    </el-option>
					    </el-select>					  
		          </el-form-item>
		
		          <el-form-item label="名称:">
					  <input type="radio" name="radios2" value="0" v-model="updateCarDataForm.pname"><label>轮胎</label>
					  <input type="radio" name="radios2" value="1" v-model="updateCarDataForm.pname"><label>过路费</label>
					  <input type="radio" name="radios2" value="2" v-model="updateCarDataForm.pname"><label>车辆维修</label>
					  <input type="radio" name="radios2" value="3" v-model="updateCarDataForm.pname"><label>保养费</label>
					  <input type="radio" name="radios2" value="4" v-model="updateCarDataForm.pname"><label>油费</label>
					  <input type="radio" name="radios2" value="5" v-model="updateCarDataForm.pname"><label>尿素</label>
					  <input type="radio" name="radios2" value="6" v-model="updateCarDataForm.pname"><label>其他</label>		          
		          </el-form-item>
		          <el-form-item label="支出金额:">
		            <el-input
		              placeholder="请输入支出金额"
		              maxlength="50"
		              v-model="updateCarDataForm.price"					  
		            />
		          </el-form-item>
		
					<el-form-item label="备注:">
					  <el-input
					    placeholder="请输入备注"
					    maxlength="50"
					    v-model="updateCarDataForm.bz"
					  />
					</el-form-item>
					
					<el-form-item label="选择支付油卡:">
						<el-select v-model="value4" placeholder="请选择" @change="getOid">
						  <el-option
							  v-for="item in oids"
							  :key="item.oidcard"
							  :label="item.oidcard"
							  :value="item.oidcard">
						  </el-option>
						  </el-select>
					  </el-form-item>
		
		          <el-form-item label="是否支付:">
					  <input type="radio" name="radios3" value="1" v-model="updateCarDataForm.ispay"><label>是</label>
					  <input type="radio" name="radios3" value="0" v-model="updateCarDataForm.ispay"><label>否</label>
		          </el-form-item>
				  
				  <el-form-item label="记录时间:">
					  <el-date-picker
					   placeholder="请选择时间"   
					  	type="date"
					   v-model="updateCarDataForm.ptime">
					  </el-date-picker>
				   
				  </el-form-item>
				  
				 
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="updateCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		      <el-pagination v-if="allCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="customerList"
						    @size-change="customerList" >
						</el-pagination>
						<el-pagination v-if="selectCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="searchCust"
						    @size-change="searchCust" >
						</el-pagination>
		    </div>
</template>

<script>
import moment from "moment"
  export default {
	  
	 
    data() {
      return {
        // 存储后端返回给前端的客户列表
        carLists: [],
        // “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
        addBox:false,
		oids:[],
		cars: [],
        allCustomer:true,
        // “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
        updateBox:false,
        selectCustomer:false,
        // “添加客户”对话框的表单
		value1: '',
		value2: '',
		value3: '',
		value4: '',
		value5: '',
		value6: '',
		cid:'',
        addCustDataForm:{
           pcarid:'',
           cid:'',
		   oidcard:'',
           pname:'',
           price:'',
           ispay:'',
           ptime:'',
		   bz: ''
        },
        // “修改客户”对话框的表单
        updateCarDataForm:{
           pcarid:'',
           cid:'',
		   oidcard:'',
           pname:'',
           price:'',
           ispay:'',
           ptime:'',
		   bz: ''
        },
        // 搜索表单
        searchCustForm:{
            cid:'',
            oidcard:'',
            pname:'',
            price:'',
            ispay:'',
            ptime:'',
            bz: ''
        },
		oidcard:'',
        total : 0,
				pageNum : 1,
				pageSize : 10,
      }
    },
    methods: {
		getOid(value){
		  this.oidcard = value;
		},
		getCid(value){
		  this.cid = value;
		},
         dateFormat(row,column){
           let date = row[column.property];
           if(date === undefined){
             return ''
           } ;
           return moment(date).format("yyyy-MM-DD")
         },
		 //导出
		 exportexcel(){
		 			 this.$alert(
		 			         <div class="el-message-box--custom-body">
		 			           <div style="font-size:16px">确认导出所有数据？</div>
		 			         </div>,
		 			         "数据导出",
		 			         {
		 			           showCancelButton: true,				          
		 			           customClass: "el-message-box--noicon el-message-box--custom",
		 			           confirmButtonText: "导出"
		 			         }
		 			       ).then(() => {
		 			         window.open('https://jingjie-back.goomo.top/pay/exportcar', "_blank");
		 					 // window.open('/car/export', "_blank");
		 			       });			 	 
		   
		 },
		 searchCust(){
		 	console.log("11"); 	
		 	this.$axios.post('/pay/search/'+this.pageNum+'/'+this.pageSize,		 	
		 			{				
		 				
		 				cid: this.cid,
		 				oidcard:this.searchCustForm.oidcard,
		 				pname: this.searchCustForm.pname,
		 				price: this.searchCustForm.price,
		 				ispay: this.searchCustForm.ispay,
		 				ptime: this.searchCustForm.ptime,
		 				bz: this.searchCustForm.bz
		 			})
		 	.then((resp) =>{
		 	if(resp.data.data.empList != null){
		 			this.carLists = resp.data.data.empList.list;
		 			this.total = resp.data.data.empList.total;
		 			this.pageNum = resp.data.data.empList.pageNum;
		 			this.allCustomer = false;
		 			this.selectCustomer = true;
					// this.cid = '';
					// this.oidcard = '';
					// this.value5 = '';
					// this.value6 = '';
		 		}else
		 		{
		 			this.$message.error("没有相关用户信息");
		 		}
		 	}).catch((error) =>{
		 		console.log(error);
		 		this.$message.error("请求发送失败");
		 	})	 		
		 	},
		// 修改之前的数据回显
		preUpdateCust(pcarid) {
		   this.$axios.get('/pay/getpca/'+pcarid)
		   .then((resp)=>{
		      // 条件成立：表示能够从后端获取对应的客户信息，完成数据回显
		      if (resp.data.message == 'success') {
		        // 显示“修改客户”对话框
		        this.updateBox = true;
		        // 将后端的客户信息赋值给前端定义的修改表单
		        this.updateCarDataForm = resp.data.data.pcarById;
				this.value1 = this.updateCarDataForm.carone;
				this.value4 = this.updateCarDataForm.oidcard;
		      } else {
		        this.$message.error("没有找到对应的客户信息");
		      }
		   })
		   .catch((error)=>{
		      this.$message.error("修改客户信息数据回显请求发送失败");
		      console.log(error);
		   })
		   this.$axios.get('/car/allcar').then((resp)=>{
		     this.cars = resp.data.data.carLists;
		   			 console.log(this.cars);
		   }).catch((error)=>{
		     this.$message.error("请求失败");
		     console.log(error);
		   })
		   this.$axios.get('/oil/getbank').then((resp)=>{
		     this.oids = resp.data.data.oidcards;
		   }).catch((error)=>{
		     this.$message.error("请求失败");
		     console.log(error);
		   })
		   
		},
		// 修改客户信息
		updateCustomer(){
		    this.$axios.post('/pay/updatepca',{
		      pcarid: this.updateCarDataForm.pcarid,
		      cid: this.cid,
			  oidcard:this.oidcard,
		      pname: this.updateCarDataForm.pname,
		      price: this.updateCarDataForm.price,
		      ispay: this.updateCarDataForm.ispay,
			  ptime: this.updateCarDataForm.ptime,
			  bz: this.updateCarDataForm.bz
			 
		    }).then((resp)=>{
		      // 条件成立：修改成功，否则修改失败
		      if (resp.data.code == 20000) {
		         this.$message.success("修改成功");
		      } else {
		         this.$message.error("修改失败");
		      }
		      // 隐藏对话框
			  this.value2='',
			  this.value4='',
		      this.updateBox = false;
		      // 重新查询修改之后的客户信息
		      this.customerList();
		    }).catch((error)=>{
		       this.$message.error("修改客户信息请求发送失败");
		       console.log(error);
		    })
		    
		},
		// 根据客户ID删除客户信息
		deleteCust(pcarid){
		    this.$confirm("确定要删除吗","提示",{
		        confirmButtonText:'确定',
		        cannelButtonText:'取消',
		        type:'警告'
		    })
		    // 点击确定执行删除操作
		    .then(()=>{
		          // 删除操作之前先做check(验证当前客户有没有管理送水历史，如果有提示用户该客户有
		          // 对应的送水历史关联)
		          this.$axios.post('/pay/deletepca/'+pcarid)
		          // then方法的resp参数封装了后端返回给前端的数据
		                    .then((resp)=>{
		                        // 条件成立：表示该客户有对应的送水历史关联，阻止删除
		                       if(resp.data.code == 20000) {
		                               this.$message.success("删除成功");
		                           } else {
		                               this.$message.error("删除失败");
		                           }
		                           this.customerList();
		                    }).catch((error)=>{
                                    this.$message.error("删除客户信息请求发送失败");
                                    console.log(error);
                                })
							
		          })
		},
		// 预添加客户信息，弹出“添加客户”对话框
		preSaveCustomer(){
			this.$axios.get('/car/allcar').then((resp)=>{
			  this.cars = resp.data.data.carLists;
						 console.log(this.cars);
			}).catch((error)=>{
			 this.$message.error("请先添加车牌号");
			  console.log(error);
			})
			this.$axios.get('/oil/getbank').then((resp)=>{
			  this.oids = resp.data.data.oidcards;
			}).catch((error)=>{
			  //this.$message.error("请求失败");
			  console.log(error);
			})
		    this.addBox = true;
		
		},
		// 关闭对话框之前做的动作
		handleClose(done){
		    // 清空上一次表单数据
		    this.addCustDataForm.cid='';
		    this.addCustDataForm.pname='';
		    this.addCustDataForm.ispay='';
			this.addCustDataForm.price='';
			this.addCustDataForm.ptime='';
			this.addCustDataForm.pcarid='';
			this.addCustDataForm.bz='';
			this.value2='';
			this.value1='';
			this.value3='';
			this.value4='';
		    // 关闭对话框
		    done();
		},
		// 提交对话框添加客户信息
		saveCustomer(){
		    this.$axios.post('/pay/insertpca',{
		        // 前端使用JSON格式将表单参数传入给后端
			   cid: this.cid,
			   oidcard:this.oidcard,
			   pname: this.addCustDataForm.pname,
			   price: this.addCustDataForm.price,
			   ispay: this.addCustDataForm.ispay,
			   ptime: this.addCustDataForm.ptime,
				 bz: this.addCustDataForm.bz
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error("添加失败");
		        }
		        // 重新查询客户信息
		        this.customerList();
		        // 关闭对话框
		        this.addBox = false;
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
        // 向后端发起异步请求，查询所有的客户信息
      customerList(){
		  this.$axios.get('/car/allcar').then((resp)=>{
		    this.cars = resp.data.data.carLists;
		  			 console.log(this.cars);
		  }).catch((error)=>{
		   this.$message.error("请先添加车牌号");
		    console.log(error);
		  })
		  this.$axios.get('/oil/getbank').then((resp)=>{
		    this.oids = resp.data.data.oidcards;
		  }).catch((error)=>{
		    //this.$message.error("请求失败");
		    console.log(error);
		  })
					this.$axios.get('/pay/allpaycar/'+this.pageNum+"/"+this.pageSize)
					        .then((resp)=>{
					            // 获取后端返回给前端的信息
					            if (resp.data.message == 'success') {
									 // this.$message.error("向后端发起客户列表请求成功");
					                // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
					                this.carLists = resp.data.data.pcarlist.list;
									this.total = resp.data.data.pcarlist.total;
									this.pageNum = resp.data.data.pcarlist.pageNum;
									this.allCustomer = true;
									this.selectCustomer = false;
					            }
					        }).catch((error)=>{
					            this.$message.error("向后端发起客户列表请求失败");
					            console.log(error);
					        })
					    }
					},
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.customerList()
    },
  }
</script>
