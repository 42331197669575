<template>
    <div id="car">
                
               <el-form :inline="true" :model="searchCustForm" class="demo-form-inline">
                  <el-button type="primary" @click="preSaveCustomer">添加</el-button>
                  &nbsp;&nbsp;&nbsp;              
				<el-form-item label="公司名称:">
		            <el-input					
		              placeholder="请输入公司名称"
		              maxlength="50"
		              v-model="searchCustForm.name"
		            />
		          </el-form-item>	
		          <el-form-item label="联系人:">
		            <el-input
		              placeholder="请输入联系人"
		              maxlength="50"
		              v-model="searchCustForm.lxr"
		            />
		          </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="searchCust">搜索</el-button>
                  </el-form-item>
                </el-form>
                <el-table
                    :data="carLists"
                    stripe
                    style="width: 100%">
					<el-table-column
					label="编号"
						 type="index"
						 width="150">
					   </el-table-column>

                   <el-table-column
                    prop="name"
                    label="公司名称"
                    width="200">
                    </el-table-column>

                    <el-table-column
                    prop="lxr"
                    label="联系人"
                    width="200">
                    </el-table-column>

                    <el-table-column
                    prop="phone"				
                    label="电话"
					width="200">								
                    </el-table-column>

                  
					<el-table-column
					prop="sh"					
					label="税号"
					width="200">
					</el-table-column>

					<el-table-column
					prop="yzdz"					
					label="邮件地址"
					width="200">
					</el-table-column>

			

                    <el-table-column
                    label="操作" width="200px">
                    <template slot-scope="scope">
                      
                        <el-button type="primary" 
                        icon="el-icon-delete" 
                        @click="deleteCust(scope.row.id)">
                        </el-button>
                      
                        <el-button type="danger" 
                        icon="el-icon-edit" 
                        @click="preUpdateCust(scope.row.id)">
                        </el-button>
                    </template>
                </el-table-column>
                </el-table>
		<el-dialog
		        title="添加客户"
		        :visible.sync="addBox"
		        width="50%"
		        :before-close="handleClose"
		       >
		        <el-form 
				
				  
		          ref="addCustDataForm"
		          label-width="100px"
		          v-model="addCustDataForm"
		        >
		          <el-form-item label="公司名称:">
		            <el-input					
		              placeholder="请输入公司名称"
		              maxlength="50"
		              v-model="addCustDataForm.name"
		            />
		          </el-form-item>
		
		          <el-form-item label="联系人:">
		            <el-input
		              placeholder="请输入联系人"
		              maxlength="50"
		              v-model="addCustDataForm.lxr"
		            />
		          </el-form-item>
		          <el-form-item label="电话:">
		            <el-input
		              placeholder="请输入电话"
		              maxlength="50"
		              v-model="addCustDataForm.phone"
		            />
		          </el-form-item>
		
		          <el-form-item label="税号:">
		            <el-input
		             placeholder="税号"   
		            maxlength="50"
		             v-model="addCustDataForm.sh"
		            />
		          </el-form-item>
		          
		          
		          <el-form-item label="邮件地址:">
		            <el-input
		             placeholder="请选择邮件地址"   
		            	maxlength="50"
		             v-model="addCustDataForm.yzdz"
					/>
		          </el-form-item>
		          
				  
				 
				
		
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="saveCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		<!-- 
		  修改客户对话框 
		  updateBox表示修改对话框显示和隐藏的标志：true显示，false隐藏
		  updateHandleClose表示关闭窗体之前发生的动作
		-->
		      <el-dialog
		        title="修改车辆"
		        :visible.sync="updateBox"
		        width="50%"
		        :before-close="updateHandleClose"
		       >
		        <el-form
		          ref="updateCustDataForm"
		          label-width="100px"
		          v-model="updateCarDataForm"
		        >
		          <el-form-item label="公司名称:">
		            <el-input					
		              placeholder="请输入公司名称"
		              maxlength="50"
		              v-model="updateCarDataForm.name"
		            />
		          </el-form-item>
		          		
		          <el-form-item label="联系人:">
		            <el-input
		              placeholder="请输入联系人"
		              maxlength="50"
		              v-model="updateCarDataForm.lxr"
		            />
		          </el-form-item>
		          <el-form-item label="电话:">
		            <el-input
		              placeholder="请输入电话"
		              maxlength="50"
		              v-model="updateCarDataForm.phone"
		            />
		          </el-form-item>
		          		
		          <el-form-item label="税号:">
		            <el-input
		             placeholder="税号"   
		            maxlength="50"
		             v-model="updateCarDataForm.sh"
		            />
		          </el-form-item>
		          
		          
		          <el-form-item label="邮件地址:">
		            <el-input
		             placeholder="请选择邮件地址"   
		            	maxlength="50"
		             v-model="updateCarDataForm.yzdz"
		          					/>
		          </el-form-item>
				  
				  
				 
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="updateCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		      <el-pagination v-if="allCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="customerList"
						    @size-change="customerList" >
						</el-pagination>
						<el-pagination v-if="selectCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="searchCust"
						    @size-change="searchCust" >
						</el-pagination>
		    </div>
                
    </div>
</template>

<script>
import moment from "moment"
  export default {
	  
	 
    data() {
      return {
		  rules: {
		    carone: [
		      { required: true, message: '请输入车牌号', trigger: 'blur' }
		    
		    ],
		    
			},
        // 存储后端返回给前端的客户列表
        carLists: [],
        // “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
        addBox:false,
		
        allCustomer:true,
        // “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
        updateBox:false,
        selectCustomer:false,
        // “添加客户”对话框的表单
        addCustDataForm:{
          
           name:'',
           lxr:'',
           phone:'',
           sh:'',
           ky:'',
		   yzdz:''
		   
        },
        // “修改客户”对话框的表单
        updateCarDataForm:{
           id:'',
           name:'',
           lxr:'',
           phone:'',
           sh:'',
           ky:'',
           yzdz:''
        },
        // 搜索表单
        searchCustForm:{
           name:'',
           lxr:'',
           phone:'',
           sh:'',
           ky:'',
           yzdz:''
        },
        total : 0,
				pageNum : 1,
				pageSize : 10,
      }
    },
    methods: {
         dateFormat(row,column){
           let date = row[column.property];
           if(date === undefined){
             return ''
           } ;
           return moment(date).format("yyyy-MM-DD")
         },
		 // 收搜
		 searchCust(){
		 	console.log("11"); 	
		 	this.$axios.post('/customer/search/'+this.pageNum+'/'+this.pageSize,		 	
		 			{				
		 				name: this.searchCustForm.name,
		 				lxr: this.searchCustForm.lxr
		 				
		 			})
		 	.then((resp) =>{
		 	if(resp.data.data.empList != null){
		 			this.carLists = resp.data.data.empList.list;
		 			this.total = resp.data.data.empList.total;
		 			this.pageNum = resp.data.data.empList.pageNum;
		 			this.allCustomer = false;
		 			this.selectCustomer = true;
		 		}else
		 		{
		 			this.$message.error("没有相关用户信息");
		 		}
		 	}).catch((error) =>{
		 		console.log(error);
		 		this.$message.error("请求发送失败");
		 	})	 		
		 	},
		// 修改之前的数据回显
		preUpdateCust(id) {
		   this.$axios.get('/customer/get/'+id)
		   .then((resp)=>{
		      // 条件成立：表示能够从后端获取对应的客户信息，完成数据回显
		      if (resp.data.message == 'success') {
		        // 显示“修改客户”对话框
		        this.updateBox = true;
		        // 将后端的客户信息赋值给前端定义的修改表单
		        this.updateCarDataForm = resp.data.data.cusById;
		      } else {
		        this.$message.error("没有找到对应的客户信息");
		      }
		   })
		   .catch((error)=>{
		      this.$message.error("修改客户信息数据回显请求发送失败");
		      console.log(error);
		   })
		},
		// 修改客户信息
		updateCustomer(){
		    this.$axios.post('/customer/update',{
		      id: this.updateCarDataForm.id,
		      name: this.updateCarDataForm.name,
		      lxr: this.updateCarDataForm.lxr,
		      phone: this.updateCarDataForm.phone,
		      sh: this.updateCarDataForm.sh,
			  yzdz: this.updateCarDataForm.yzdz
			 
			 
		    }).then((resp)=>{
		      // 条件成立：修改成功，否则修改失败
		      if (resp.data.code == 20000) {
		         this.$message.success("修改成功");
		      } else {
		         this.$message.error(resp.data.message);
		      }
		      // 隐藏对话框
		      this.updateBox = false;
		      // 重新查询修改之后的客户信息
		      this.customerList();
		    }).catch((error)=>{
		       this.$message.error("修改客户信息请求发送失败");
		       console.log(error);
		    })
		    
		},
		// 根据客户ID删除客户信息
		deleteCust(id){
		    this.$confirm("确定要删除吗","提示",{
		        confirmButtonText:'确定',
		        cannelButtonText:'取消',
		        type:'警告'
		    })
		    // 点击确定执行删除操作
		    .then(()=>{
		          // 删除操作之前先做check(验证当前客户有没有管理送水历史，如果有提示用户该客户有
		          // 对应的送水历史关联)
		          this.$axios.post('/customer/delete/'+id)
		          // then方法的resp参数封装了后端返回给前端的数据
		                    .then((resp)=>{
		                        // 条件成立：表示该客户有对应的送水历史关联，阻止删除
		                       if(resp.data.code == 20000) {
		                               this.$message.success("删除成功");
		                           } else {
		                               this.$message.error(resp.data.message);
		                           }
		                           this.customerList();
		                    }).catch((error)=>{
                                    this.$message.error("删除客户信息请求发送失败");
                                    console.log(error);
                                })
							
		          })
		},
		// 预添加客户信息，弹出“添加客户”对话框
		preSaveCustomer(){
		    this.addBox = true;
		
		},
		// 关闭对话框之前做的动作
		handleClose(done){
		    // 清空上一次表单数据
		    this.addCustDataForm.name='';
		    this.addCustDataForm.lxr='';
		    this.addCustDataForm.phone='';
			this.addCustDataForm.sh='';
			this.addCustDataForm.yzdz='';
			
		    // 关闭对话框
		    done();
		},
		// 提交对话框添加客户信息
		saveCustomer(){
		    this.$axios.post('/customer/insert',{
		        // 前端使用JSON格式将表单参数传入给后端
		     
		      name: this.addCustDataForm.name,
		      lxr: this.addCustDataForm.lxr,
		      phone: this.addCustDataForm.phone,
		      sh: this.addCustDataForm.sh,
		      yzdz: this.addCustDataForm.yzdz
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error(resp.data.message);
		        }
		        // 重新查询客户信息
		        this.customerList();
		        // 关闭对话框
		        this.addBox = false;
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
        // 向后端发起异步请求，查询所有的客户信息
      customerList(){
					this.$axios.get('/customer/all/'+this.pageNum+"/"+this.pageSize)
					        .then((resp)=>{
					            // 获取后端返回给前端的信息
					            if (resp.data.message == 'success') {
									 // this.$message.error("向后端发起客户列表请求成功");
					                // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
					                this.carLists = resp.data.data.cslist.list;
									this.total = resp.data.data.cslist.total;
									this.pageNum = resp.data.data.cslist.pageNum;
									this.allCustomer = true;
									this.selectCustomer = false;
					            }
					        }).catch((error)=>{
					            this.$message.error("向后端发起客户列表请求失败");
					            console.log(error);
					        })
					    }
					},
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.customerList()
    },
  }
</script>
