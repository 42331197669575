<template>
<!--送水工管理系统最外层的容器-->
  <div id="app">
    <!-- 显示当前路由级别下一级的页面。 -->
    <router-view/>
  </div>
</template>

<style>
  #app{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: pink;
    /* overflow: hidden; */
  }
</style>
<script>
	export default{
	name: "app",
	mounted(){
		document.body.style.zoom = 0.86
	}
};
</script>