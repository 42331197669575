<template>
    <div id="bank"> 
               <el-form :inline="true" :model="searchCustForm" class="demo-form-inline">
                  <el-button type="primary" @click="exportexcel">导出</el-button>
                  &nbsp;&nbsp;&nbsp;
				  <el-button type="primary" @click="preSaveCustomer">添加记录</el-button>
                  &nbsp;&nbsp;&nbsp;
				 
                  <el-form-item label="银行卡号">
                    <el-input v-model="searchCustForm.bankcard" required="true" placeholder="银行卡号">
                    </el-input>
                  </el-form-item>

				<el-form-item label="类型:">
					  <input type="radio" name="radios11" value="1" v-model="searchCustForm.btype"><label>支出</label>
					  <input type="radio" name="radios11" value="0" v-model="searchCustForm.btype"><label>收入</label>
					  <input type="radio" name="radios11" value="" v-model="searchCustForm.btype"><label>全选</label>
		          </el-form-item>
		         	
		          <el-form-item label="时间:">
					  <el-date-picker
					   placeholder="请选择时间"   
					  	type="date"
					   v-model="searchCustForm.time">
					  </el-date-picker>		        
		          </el-form-item>
				  
				  <el-form-item label="备注:">
				    <el-input
				      placeholder="请输入备注"
				      maxlength="50"
				      v-model="searchCustForm.bz"
				    />
				  </el-form-item>
				
                  <el-form-item>
                    <el-button type="primary" @click="searchCust">搜索</el-button>
                  </el-form-item>
                </el-form>
                <el-table
                    :data="carLists"
                    stripe
                    style="width: 100%">
					<el-table-column
					label="编号"
						 type="index"
						 width="150">
					   </el-table-column>

                  <el-table-column
                    prop="bankcard"
                    label="银行卡号"
                    width="180">
                    </el-table-column>

                    <el-table-column
                    prop="btype"
                    label="类型"
                    width="180">
					<template slot-scope="scope">{{ scope.row.btype == 1 ? '支出' : '收入' }}</template>
                    </el-table-column>

                    <el-table-column
                    prop="price"				
                    label="金额"
					width="180">
                    </el-table-column>

                    <el-table-column
                    prop="time"
					:formatter="dateFormat"
                    label="时间"
                    width="180">
                    </el-table-column>
					
					<el-table-column
					prop="bz"
					label="备注">
					</el-table-column>			

                    <el-table-column
                    label="操作" width="200px">
                    <template slot-scope="scope">
                      
                        <el-button type="primary" 
                        icon="el-icon-delete" 
                        @click="deleteCust(scope.row.bankid)">
                        </el-button>
                      
                        <el-button type="danger" 
                        icon="el-icon-edit" 
                        @click="preUpdateCust(scope.row.bankid)">
                        </el-button>
                    </template>
                </el-table-column>
                </el-table>
		
				
				
			<el-dialog
			        title="添加记录"
			        :visible.sync="addBox"
			        width="50%"
			        :before-close="handleClose"
			       >
			        <el-form
			          ref="addCustDataForm"
			          label-width="100px"
			          v-model="addCustDataForm"
			        >	
				<el-form-item label="银行卡:">
				<el-select v-model="value1" placeholder="请选择" @change="getWid">
				  <el-option
				      v-for="item in banks"
				      :key="item.bankcard"
				      :label="item.bankcard"
				      :value="item.bankcard">
				  </el-option>
				  </el-select>
				</el-form-item>
		
		          <el-form-item label="类型:">
					  <input type="radio" name="radios1" value="1" v-model="addCustDataForm.btype"><label>支出</label>
					  <input type="radio" name="radios1" value="0" v-model="addCustDataForm.btype"><label>收入</label>
		    
		          </el-form-item>
		          <el-form-item label="价格:">
		            <el-input
		              placeholder="请输入价格"
		              maxlength="50"
		              v-model="addCustDataForm.price"
		            />
		          </el-form-item>
		
		          <el-form-item label="时间:">
					  <el-date-picker
					   placeholder="请选择时间"   
					  	type="date"
					   v-model="addCustDataForm.time">
					  </el-date-picker>
		            <!-- <el-input
		              placeholder="请输入时间"
		              maxlength="50"
		              v-model="addCustDataForm.time"
		            /> -->
		          </el-form-item>
				  
				  <el-form-item label="备注:">
				    <el-input
				      placeholder="请输入备注"
				      maxlength="50"
				      v-model="addCustDataForm.bz"
				    />
				  </el-form-item>
				
		
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="saveCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
		<!-- 
		  修改客户对话框 
		  updateBox表示修改对话框显示和隐藏的标志：true显示，false隐藏
		  updateHandleClose表示关闭窗体之前发生的动作
		-->
		      <el-dialog
		        title="修改记录"
		        :visible.sync="updateBox"
		        width="50%"
		        :before-close="updateHandleClose"
		       >
		        <el-form
		          ref="updateCustDataForm"
		          label-width="100px"
		          v-model="updateCarDataForm"
		        >
		          <el-form-item label="银行卡号:">
		            <el-input
					  disabled="true"
		              placeholder="请输入银行卡号"
		              maxlength="50"
		              v-model="updateCarDataForm.bankcard"
		            />
		          </el-form-item>
		
		          <el-form-item label="类型:">
					  <input type="radio" name="radios2" value="1" v-model="updateCarDataForm.btype"><label>支出</label>
					  <input type="radio" name="radios2" value="0" v-model="updateCarDataForm.btype"><label>收入</label>
		          </el-form-item>
		          <el-form-item label="价格:">
		            <el-input
		              placeholder="请输入价格"
		              maxlength="50"
		              v-model="updateCarDataForm.price"
					  
		            />
		          </el-form-item>
		
		          <el-form-item label="时间:">
		            <el-date-picker
		             placeholder="请选择时间"   
		            	type="date"
		             v-model="updateCarDataForm.time">
		            </el-date-picker>
		          </el-form-item>
				  
				  <el-form-item label="备注:">
				    <el-input
				      placeholder="请输入备注"
				      maxlength="50"
				      v-model="updateCarDataForm.bz"
				    />
				  </el-form-item>
				  
				 
		        </el-form>
		        <span slot="footer" class="dialog-footer">
		          <el-button type="primary" @click="updateCustomer">确 定</el-button>
		        </span>
		      </el-dialog>
			  
			  <el-dialog
			          title="添加银行卡"
			          :visible.sync="addBox2"
			          width="50%"
			         :before-close="handleClose2"
			         >
			          <el-form
			            ref="addCustDataForm2"
			            label-width="100px"
			            v-model="addCustDataForm2"
			          >		
			  
			  	
			  		<el-form-item label="银行卡:">
			  		  <el-input
			  		    placeholder="请输入银行卡"
			  		    maxlength="50"
			  		    v-model="addCustDataForm2.bankcard"
			  		  />
			  		</el-form-item>
					</el-form>
			  		<span slot="footer" class="dialog-footer">
			  		    <el-button type="primary" @click="saveCustomer2">确 定</el-button>
			  		  </span>
			  		</el-dialog>
			  
			  
			  
			  
		      <el-pagination v-if="allCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="customerList"
						    @size-change="customerList" >
						</el-pagination>
						<el-pagination v-if="selectCustomer"
						    background
						    layout="sizes,prev, pager, next,jumper,->,total"
						    :page-size.sync="pageSize"
						    :current-page.sync="pageNum"
						    :page-sizes="[3,5,10]"
						    :total="total"
						    :hide-on-single-page="true"
						    @current-change="searchCust"
						    @size-change="searchCust" >
						</el-pagination>
		    </div>

</template>

<script>
import moment from "moment"
  export default {
	  
	 
    data() {
      return {
        // 存储后端返回给前端的客户列表
		value1:'',
		banks: [],
        carLists: [],
        // “添加客户”对话框 隐藏和显示的标志变量，false表示隐藏
        addBox:false,
		addBox2:false,
        allCustomer:true,
        // “修改客户”对话框 隐藏和显示的标志变量，false表示隐藏
        updateBox:false,
        selectCustomer:false,
		bankcard:'',
        // “添加客户”对话框的表单
        addCustDataForm:{
           bankid:'',
           bankcard:'',
           btype:'',
           price:'',
           time:'',
           bz:''
        },
		addCustDataForm2:{
		   bankid:'',
		   bankcard:'',
		   btype:'',
		   price:'',
		   time:'',
		   bz:''
		},
        // “修改客户”对话框的表单
        updateCarDataForm:{
            bankid:'',
            bankcard:'',
            btype:'',
            price:'',
            time:'',
            bz:''
        },
        // 搜索表单
        searchCustForm:{
            bankcard:'',
            btype:'',
            price:'',
            time:'',
            bz:''
        },
        total : 0,
				pageNum : 1,
				pageSize : 10,
      }
    },
    methods: {
		getWid(value){
		  this.bankcard = value;
		},
         dateFormat(row,column){
           let date = row[column.property];
           if(date === undefined){
             return ''
           } ;
           return moment(date).format("yyyy-MM-DD")
         },
		 //导出
		 exportexcel(){
		 			 this.$alert(
		 			         <div class="el-message-box--custom-body">
		 			           <div style="font-size:16px">确认导出所有数据？</div>
		 			         </div>,
		 			         "数据导出",
		 			         {
		 			           showCancelButton: true,				          
		 			           customClass: "el-message-box--noicon el-message-box--custom",
		 			           confirmButtonText: "导出"
		 			         }
		 			       ).then(() => {
		 			         window.open('https://jingjie-back.goomo.top/bank/export', "_blank");
		 					 // window.open('/car/export', "_blank");
		 			       });			 	 
		   
		 },
		 
		 searchCust(){
		 	console.log("11"); 	
		 	this.$axios.post('/bank/search/'+this.pageNum+'/'+this.pageSize,		 	
		 			{				
		 				bankcard: this.searchCustForm.bankcard,
		 				btype: this.searchCustForm.btype,
		 				price: this.searchCustForm.price,
		 				time: this.searchCustForm.time,
		 				bz: this.searchCustForm.bz		
		 			})
		 	.then((resp) =>{
		 	if(resp.data.data.empList != null){
		 			this.carLists = resp.data.data.empList.list;
		 			this.total = resp.data.data.empList.total;
		 			this.pageNum = resp.data.data.empList.pageNum;
		 			this.allCustomer = false;
		 			this.selectCustomer = true;
		 		}else
		 		{
		 			this.$message.error("没有相关用户信息");
		 		}
		 	}).catch((error) =>{
		 		console.log(error);
		 		this.$message.error("请求发送失败");
		 	})	 		
		 	},
		// 修改之前的数据回显
		preUpdateCust(bankid) {
		   this.$axios.get('/bank/get/'+bankid)
		   .then((resp)=>{
		      // 条件成立：表示能够从后端获取对应的客户信息，完成数据回显
		      if (resp.data.message == 'success') {
		        // 显示“修改客户”对话框
		        this.updateBox = true;
		        // 将后端的客户信息赋值给前端定义的修改表单
		        this.updateCarDataForm = resp.data.data.bankById;
		      } else {
		        this.$message.error("没有找到对应的客户信息");
		      }
		   })
		   .catch((error)=>{
		      this.$message.error("修改客户信息数据回显请求发送失败");
		      console.log(error);
		   })
		},
		// 修改客户信息
		updateCustomer(){
		    this.$axios.post('/bank/update',{
		      bankid: this.updateCarDataForm.bankid,
		      bankcard: this.updateCarDataForm.bankcard,
		      btype: this.updateCarDataForm.btype,
		      price: this.updateCarDataForm.price,
		      time: this.updateCarDataForm.time,
			  bz: this.updateCarDataForm.bz
			 
		    }).then((resp)=>{
		      // 条件成立：修改成功，否则修改失败
		      if (resp.data.code == 20000) {
		         this.$message.success("修改成功");
		      } else {
		         this.$message.error(resp.data.message);
		      }
		      // 隐藏对话框
		      this.updateBox = false;
		      // 重新查询修改之后的客户信息
		      this.customerList();
		    }).catch((error)=>{
		       this.$message.error("修改客户信息请求发送失败");
		       console.log(error);
		    })
		    
		},
		// 根据客户ID删除客户信息
		deleteCust(bankid){
		    this.$confirm("确定要删除吗","提示",{
		        confirmButtonText:'确定',
		        cannelButtonText:'取消',
		        type:'警告'
		    })
		    // 点击确定执行删除操作
		    .then(()=>{
				console.log(1);
		          // 删除操作之前先做check(验证当前客户有没有管理送水历史，如果有提示用户该客户有
		          // 对应的送水历史关联)
		          this.$axios.post('/bank/delete/'+bankid)
		          // then方法的resp参数封装了后端返回给前端的数据
		                    .then((resp)=>{
		                        // 条件成立：表示该客户有对应的送水历史关联，阻止删除
								console.log(1);
		                       if(resp.data.code == 20000) {
		                               this.$message.success("删除成功");
									   console.log(3);
		                           } else {
		                               this.$message.error(resp.data.message);
									   console.log(4);
		                           }
								   console.log(5);
		                           this.customerList();
		                    }).catch((error)=>{
                                    this.$message.error("删除客户信息请求发送失败");
                                    console.log(error);
                                }
							)
		          })
		},
		// 预添加客户信息，弹出“添加客户”对话框
		preSaveCustomer(){
			this.$axios.get('/bankcard/getbank').then((resp)=>{
			  this.banks = resp.data.data.bankcard;
			}).catch((error)=>{
			  this.$message.error("请求失败");
			  console.log(error);
			})
			
			
			
			
		    this.addBox = true;
		
		},
		preSave1Customer(){
			this.addBox2 = true;
		},
		// 关闭对话框之前做的动作
		handleClose(done){
		    // 清空上一次表单数据
		    this.addCustDataForm.bankcard='';
		    this.addCustDataForm.btype='';
		    this.addCustDataForm.price='';
			this.addCustDataForm.time='';
			this.addCustDataForm.bz='';
			this.value1='';
			this.bankcard='';
		    // 关闭对话框
		    done();
		},
		handleClose2(done){
		    // 清空上一次表单数据
		    this.addCustDataForm2.bankcard='';
		    this.addCustDataForm2.btype='';
		    this.addCustDataForm2.price='';
			this.addCustDataForm2.time='';
			this.addCustDataForm2.bz='';
			this.bankcard='';
		    // 关闭对话框
		    done();
		},
		// 提交对话框添加客户信息
		saveCustomer(){
		    this.$axios.post('/bank/insert',{
		        // 前端使用JSON格式将表单参数传入给后端
		       bankid: this.addCustDataForm.bankid,
		       bankcard: this.bankcard,
		       btype: this.addCustDataForm.btype,
		       price: this.addCustDataForm.price,
		       time: this.addCustDataForm.time,
		       bz: this.addCustDataForm.bz
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error(resp.data.message);
		        }
		        // 重新查询客户信息
		        this.customerList();
				this.bankcard='';
		        // 关闭对话框
		        this.addBox = false;
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
		saveCustomer2(){
		    this.$axios.post('/bank/insert2',{
		        // 前端使用JSON格式将表单参数传入给后端
		       
		       bankcard: this.addCustDataForm2.bankcard,
		       btype: 0,
		       price: 0,
		       time: this.addCustDataForm2.time,
		       bz: "添加银行卡"
		    }).then((resp)=>{
		        // 接受后端返回给前端你的消息
		        // 条件成立：表示插入成功，给客户成功的提示，否则给客户失败的提示
		        if(resp.data.code == 20000) {
		            this.$message.success("添加成功");
		        } else {
		            this.$message.error(resp.data.message);
		        }
		        // 重新查询客户信息
		        this.customerList();
				this.bankcard='';
		        // 关闭对话框
		        this.addBox = false;
		    }).catch((error)=>{
		        this.$message.error("添加客户请求发送失败");
		        console.log(error);
		    })
		
		},
        // 向后端发起异步请求，查询所有的客户信息
      customerList(){
					this.$axios.get('/bank/all/'+this.pageNum+"/"+this.pageSize)
					        .then((resp)=>{
					            // 获取后端返回给前端的信息
					            if (resp.data.message == 'success') {
									 // this.$message.error("向后端发起客户列表请求成功");
					                // 后端返回给前端的数据(resp.data.responseData),赋值给变量custList
					                this.carLists = resp.data.data.bankslist.list;
									this.total = resp.data.data.bankslist.total;
									this.pageNum = resp.data.data.bankslist.pageNum;
									this.allCustomer = true;
									this.selectCustomer = false;
					            }
					        }).catch((error)=>{
					            this.$message.error("向后端发起客户列表请求失败");
					            console.log(error);
					        })
					    }
					},
    // created() 是vue页面加载成功之后自动执行的一个方法，用来做页面初始化
    created() {
       this.customerList()
    },
  }
</script>
